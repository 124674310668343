/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Divider, Flex, Text } from "@aws-amplify/ui-react";
import MyIcon from "./MyIcon";
export default function FAQ(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="137px"
      direction="row"
      width="1433px"
      height="786px"
      justifyContent="space-between"
      alignItems="flex-start"
      position="relative"
      padding="120px 120px 120px 120px"
      {...getOverrideProps(overrides, "FAQ")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="12px 0px 12px 0px"
        {...getOverrideProps(overrides, "Frame 1160464421")}
      >
        <Text
          fontFamily="Inter"
          fontSize="48px"
          fontWeight="700"
          color="rgba(30,27,27,1)"
          lineHeight="60px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="444px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Frequently Asked Questions"
          {...getOverrideProps(overrides, "Frequently Asked Questions")}
        ></Text>
      </Flex>
      <Flex
        gap="0"
        direction="column"
        width="603px"
        height="536px"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 116046441938561881")}
      >
        <Flex
          gap="32px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="24px 10px 24px 10px"
          {...getOverrideProps(overrides, "Frame 1160464417")}
        >
          <Flex
            gap="32px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 1160464428")}
          >
            <Flex
              gap="32px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 1160464426")}
            >
              <MyIcon
                width="32px"
                height="32px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                type="Add"
                {...getOverrideProps(overrides, "MyIcon38561885")}
              ></MyIcon>
            </Flex>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="flex-start"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 1160464427")}
            >
              <Text
                fontFamily="Inter"
                fontSize="24px"
                fontWeight="500"
                color="rgba(30,27,27,1)"
                lineHeight="36px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="-0.57px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Why do I need to create an account"
                {...getOverrideProps(
                  overrides,
                  "Why do I need to create an account38561887"
                )}
              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="18px"
                fontWeight="400"
                color="rgba(30,27,27,1)"
                lineHeight="30.600000381469727px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Work has to fit into life, not the other way around. We offer an optional one on one guided tour through the Ikimy process. Additionally, we provide other paid personal surveys and assessments to help you build greater awareness and direction on the life you want to lead."
                {...getOverrideProps(
                  overrides,
                  "Work has to fit into life, not the other way around. We offer an optional one on one guided tour through the Ikimy process. Additionally, we provide other paid personal surveys and assessments to help you build greater awareness and direction on the life you want to lead."
                )}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
        <Divider
          width="unset"
          height="unset"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          opacity="0.5"
          size="small"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider38561889")}
        ></Divider>
        <Flex
          gap="32px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="24px 10px 24px 10px"
          {...getOverrideProps(overrides, "Frame 1160464418")}
        >
          <MyIcon
            width="32px"
            height="32px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            type="Add"
            {...getOverrideProps(overrides, "MyIcon38561891")}
          ></MyIcon>
          <Text
            fontFamily="Inter"
            fontSize="24px"
            fontWeight="500"
            color="rgba(30,27,27,1)"
            lineHeight="36px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="-0.57px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Why do I need to create an account"
            {...getOverrideProps(
              overrides,
              "Why do I need to create an account38561892"
            )}
          ></Text>
        </Flex>
        <Divider
          width="unset"
          height="unset"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          opacity="0.5"
          size="small"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider38561893")}
        ></Divider>
        <Flex
          gap="32px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="24px 10px 24px 10px"
          {...getOverrideProps(overrides, "Frame 116046441938561894")}
        >
          <MyIcon
            width="32px"
            height="32px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            type="Add"
            {...getOverrideProps(overrides, "MyIcon38561895")}
          ></MyIcon>
          <Text
            fontFamily="Inter"
            fontSize="24px"
            fontWeight="500"
            color="rgba(30,27,27,1)"
            lineHeight="36px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="-0.57px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Why do I need to create an account"
            {...getOverrideProps(
              overrides,
              "Why do I need to create an account38561896"
            )}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
