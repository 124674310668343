/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import AppLogo from "./AppLogo";
import { Button, Flex, Icon, Text } from "@aws-amplify/ui-react";
export default function ResetPasswordSuccess(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="24px"
      direction="column"
      width="762px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      borderRadius="4px"
      padding="64px 60px 64px 60px"
      {...getOverrideProps(overrides, "ResetPasswordSuccess")}
      {...rest}
    >
      <AppLogo
        display="flex"
        gap="4.2016801834106445px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="6.722688674926758px 6.722688674926758px 6.722688674926758px 6.722688674926758px"
        darkMode="False"
        {...getOverrideProps(overrides, "AppLogo")}
      ></AppLogo>
      <Flex
        padding="0px 0px 0px 0px"
        width="117px"
        height="117px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "Group 911")}
      >
        <Icon
          width="65.36px"
          height="48.34px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 65.35867309570312,
            height: 48.33538055419922,
          }}
          paths={[
            {
              d: "M64.3871 0.919387C65.6619 2.16534 65.6852 4.2088 64.4393 5.48358L22.5566 48.3354L0.789076 23.2307C-0.378681 21.884 -0.233555 19.8455 1.11323 18.6778C2.46 17.51 4.49844 17.6551 5.6662 19.0019L22.8408 38.8094L59.8229 0.97159C61.0688 -0.303194 63.1123 -0.326566 64.3871 0.919387Z",
              fill: "rgba(119,202,133,1)",
              fillRule: "evenodd",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="33.89px"
          left="25.82px"
          {...getOverrideProps(overrides, "Vector 4 (Stroke)")}
        ></Icon>
        <Icon
          width="117px"
          height="117px"
          viewBox={{ minX: 0, minY: 0, width: 117, height: 117 }}
          paths={[
            {
              d: "M58.5 110.545C87.2436 110.545 110.545 87.2436 110.545 58.5C110.545 29.7564 87.2436 6.45517 58.5 6.45517C29.7564 6.45517 6.45517 29.7564 6.45517 58.5C6.45517 87.2436 29.7564 110.545 58.5 110.545ZM58.5 117C90.8087 117 117 90.8087 117 58.5C117 26.1913 90.8087 0 58.5 0C26.1913 0 0 26.1913 0 58.5C0 90.8087 26.1913 117 58.5 117Z",
              fill: "rgba(119,202,133,1)",
              fillRule: "evenodd",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="0px"
          {...getOverrideProps(overrides, "Ellipse 1 (Stroke)")}
        ></Icon>
      </Flex>
      <Text
        fontFamily="Inter"
        fontSize="32px"
        fontWeight="700"
        color="rgba(119,202,133,1)"
        lineHeight="40px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="568.6px"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Reset Successful!"
        {...getOverrideProps(overrides, "Reset Successful!")}
      ></Text>
      <Button
        width="unset"
        height="unset"
        shrink="0"
        alignSelf="stretch"
        size="default"
        isDisabled={false}
        variation="primary"
        children="Sign in"
        {...getOverrideProps(overrides, "Button")}
      ></Button>
    </Flex>
  );
}
