import '../account.css';
import { Signup } from '../../../ui-components';
import { useState } from 'react';
import { useEffect, useCallback, useMemo } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { signUp as signUpUser } from '../../../actions/reducers/reducers';
import Verification from './Verification';
import Success from './Success';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { resetState } from '../../../actions/action';
import Loader from '../../../utils/loader/Loader';
import GetDiscovered from '../GetDiscovered/GetDiscovered';
import {PrivacyPolicyTermsUseCombined, Close} from '../../../ui-components';
import { Button, Flex, Text } from "@aws-amplify/ui-react";

const selectSignUpLoading = (state) => {return state.account.signUpLoading};
const selectSignUpError = (state) => {return state.account.signUpError};
const selectSignUpStatus = (state) => {return state.account.signUpStatus};

function SignUp() {

    const signUpLoading = useSelector((state) => selectSignUpLoading(state));
    const signUpError = useSelector((state) => selectSignUpError(state));
    const signUpStatus = useSelector((state) => selectSignUpStatus(state));

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [dialCode, setDialCode] = useState("+1");
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [agreeTermsAndPolicy, setAgreeTermsAndPolicy] = useState(false);

    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [agreeTermsAndPolicyError, setAgreeTermsAndPolicyError] = useState('');

    const [tosAndPrivatePolicyVisible, setTosAndPrivatePolicyVisible] = useState(false);

    const location = useLocation();

    useEffect(() => {
        if (location.state?.email) {
            setEmail(location.state.email);
        }
    }, [location]);

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };
    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    };
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    };
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };
    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };
    const handleAgreeTermsAndPolicy = (event) => {
        setAgreeTermsAndPolicy(event.target.checked);
    };
    const handleDialCodeChange = (event) => {
        setDialCode(event.target.value)
    };


    const validateFirstName = useCallback(() => {
        if (firstName.trim() === '') {
            setFirstNameError('First name is required.')
            return false;
        }
        setFirstNameError('');
        return true;
    }, [firstName]);

    const validateLastName = useCallback(() => {
        if (lastName.trim() === '') {
            setLastNameError('Last name is required.')
            return false;
        }
        setLastNameError('');
        return true;
    }, [lastName]);

    const validateEmail = useCallback(() => {
        if (email.trim() === '') {
            setEmailError('Email address is required.')
            return false;
        }
        setEmailError('');
        return true;
    }, [email]);

    useEffect(() => {
        if (signUpError) {
            console.log(signUpError);
            if ((signUpError.status === 409) && (signUpError.message === `User with this email ${email} already exists.`)) {
                setEmailError('User with this email address already exists.')
                //return false;
            }
        }
    }, [signUpError, email]);

    const validatePhone = useCallback(() => {
        const VALID_PHONE_NUMER_REGEX = /^\+\d{1,3}\d{10}$/;

        if (phone.trim() === '') {
            setPhoneError('Phone number is required.')
            return false;
        }

        else if (!VALID_PHONE_NUMER_REGEX.test(dialCode + phone)) {
            setPhoneError('Invalid phone number (do not put spaces or dashes).')
            return false;
        }

        setPhoneError('');
        return true;
    }, [phone, dialCode]);

    const validatePassword = useCallback(() => {
        if (password === '') {
            setPasswordError('Password is required.')
            return false;
        }
        if (password.trim() !== password) {
            setPasswordError("Password cannot begin or end with a space");
            return false;
        }
        if (password.length < 10) {
            setPasswordError("Password must be at least 10 characters long")
            return false;
        }
        if (!/[0-9]/.test(password)) {
            setPasswordError("Password must include at least one number");
            return false;
        }

        if (!/[A-Z]/.test(password)) {
            setPasswordError("Password must include at least one uppercase letter");
            return false;
        }
        if (!/[a-z]/.test(password)) {
            setPasswordError("Password must include at least one lowercase letter");
            return false;
        }

        const VALID_PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^\$\*\.\[\]\{\}\(\)\?\"\!@#%&\/\\,><\':;|_~\`+= -])[A-Za-z\d\^\$\*\.\[\]\{\}\(\)\?\"\!@#%&\/\\,><\':;|_~\`+= -]{10,}$/;
        if (!VALID_PASSWORD_REGEX.test(password)) {
            setPasswordError("Password must include at least one special symbol");
            return false;
        }

        setPasswordError('');
        return true;
    }, [password]);

    const validateConfirmPassword = useCallback(() => {
        if (confirmPassword === '') {
            setConfirmPasswordError('Please confirm your password.');
            return false;
        }
        if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match.');
            return false;
        }
        setConfirmPasswordError('');
        return true;
    }, [confirmPassword, password]);

    const validateAgreeTermsAndPolicy = useCallback(() => {
        if (!agreeTermsAndPolicy) {
            setAgreeTermsAndPolicyError('Please agree with Terms of service and Privacy Policy.')
            return false;
        }
        setAgreeTermsAndPolicyError('');
        return true;
    }, [agreeTermsAndPolicy]);


    const handleSignUp = useCallback( async (event) => {
        // Handle sign-up logic here
        event.preventDefault();
      
        const validations = [
            validateFirstName,
            validateLastName,
            validateEmail,
            validatePhone,
            validatePassword,
            validateConfirmPassword,
            validateAgreeTermsAndPolicy
        ];

        const allValid = validations.map(validation => validation()).every(result => result)

        if (allValid) {
            const signUpRequestBody = {
                email: email,
                password: password,
                confirm_password: confirmPassword,
                given_name: firstName,
                family_name: lastName,
                phone: dialCode + phone
            };
            signUpUser(dispatch, signUpRequestBody);
        }
  
        


        // const response = await fetch(
        //     '/signin',
        //     {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify(data)

        //     }
        // );

        // if (response.ok) {
        //     const data = await response.json();
        //     localStorage.setItem('token', data.AuthenticationResult.AccessToken);
        //     window.location.href = '/dashboard';
        // }
    }, [dispatch, firstName, lastName, email, phone, dialCode, password, confirmPassword, validateFirstName, validateLastName, validateEmail, validatePassword, validateConfirmPassword, validatePhone, validateAgreeTermsAndPolicy]);

    const navigateToSignIn = useCallback(() => {
        dispatch(resetState());
        navigate('/signin')
    }, [navigate, dispatch]);

    const signupOverrides = useMemo(() => {
        
        return {
        
            Signup: {
                height: "100vh",
                width: "50vw",
            },
        
            TextField38493588: {
                value: firstName,
                onChange: handleFirstNameChange,
                hasError: firstNameError ? true : false,
                errorMessage: firstNameError
            },

            TextField38493589: {
                value: lastName,
                onChange: handleLastNameChange,
                hasError: lastNameError ? true : false,
                errorMessage: lastNameError
            },

            TextField38493591: {
                value: email,
                onChange: handleEmailChange,
                hasError: emailError ? true : false,
                errorMessage: emailError
            },
    
            PhoneNumberField: {
                value: phone,
                onChange: handlePhoneChange,
                placeholder: "Type your phone number (Eg. 6045551234)",
                hasError: phoneError ? true : false,
                errorMessage: phoneError,
                onDialCodeChange: handleDialCodeChange
                
            },

            PasswordField38493595: {
                label: "Password *",
                value: password,
                onChange: handlePasswordChange,
                hasError: passwordError ? true : false,
                errorMessage: passwordError
            },
    
            PasswordField38493596: {
                label: "Confirm Password *",
                value: confirmPassword,
                onChange: handleConfirmPasswordChange,
                hasError: confirmPasswordError ? true : false,
                errorMessage: confirmPasswordError
            },

            CheckboxField: {
                checked: agreeTermsAndPolicy,
                onChange: (event) => {
                    if (!event.target.checked) {
                        setAgreeTermsAndPolicy(false);
                    }

                    else {
                        setTosAndPrivatePolicyVisible(true);
                    }
                },
                hasError: agreeTermsAndPolicyError ? true : false,
                errorMessage: agreeTermsAndPolicyError
            },
    
            Button: {
                onClick: handleSignUp
            },

            "Already have an account? Login": {
                children: (
                    <>
                        Already have an account?{' '}
                        <span
                            onClick={navigateToSignIn}
                            style={{ fontWeight: '600', color: 'rgba(49,91,155,1)', textDecoration: 'none', cursor: 'pointer' }}
                        >
                            Login
                        </span>
                    </>
                ),
            },
        };

    }, [firstNameError, firstName, lastNameError, lastName, emailError, email, phoneError, phone, passwordError, password, confirmPasswordError, confirmPassword, agreeTermsAndPolicyError, agreeTermsAndPolicy, handleSignUp, navigateToSignIn ]);


    return (
        <div className="container">
            <div className="side-by-side">
                <GetDiscovered></GetDiscovered>
                <div className="form-container">
                    {tosAndPrivatePolicyVisible &&
                        <div className="tos-privacy-modal">
                            <div className="modal-backdrop">
                            </div>
                            <Flex
                                width="80%"
                                height="70%"
                                display="inline-flex"
                                padding="10px 20px 20px 20px"
                                direction="column"
                                justifyContent="center"
                                alignItems="flex-start"
                                backgroundColor="rgba(255,255,255,1)"
                                borderRadius="4px"
                                gap="20px"
                            >
                                <Flex
                                    direction="row"
                                    width="100%"
                                    height="5%"
                                    justifyContent="space-between"
                                >
                                    <Text
                                        fontSize="22px"
                                        fontWeight="600"
                                    >
                                        Terms of Use and Privacy Policy
                                    </Text>
                                    <Close
                                        onClick={(event) => {setTosAndPrivatePolicyVisible(false)}}
                                        style={{
                                            cursor: "pointer"
                                        }}
                                    ></Close>
                                </Flex>
                                <PrivacyPolicyTermsUseCombined
                                    width="100%"
                                    height="80%"
                                    overflow="scroll"
                                    overrides={{
                                        Button: {
                                            variation: "link",
                                            children: "",
                                            isDisabled: true
                                        }
                                    }}
                                ></PrivacyPolicyTermsUseCombined>
                               <Flex
                                    direction="column"
                                    width="100%"
                                    height="10%"
                                    justifyContent="center"
                                    alignItems="center"
                                    alignSelf="center"
                               >
                                <Button
                                    width="unset"
                                    height="unset"
                                    shrink="0"
                                    alignSelf="stretch"
                                    size="default"
                                    isDisabled={false}
                                    variation="primary"
                                    children="I agree"
                                    onClick={(event) => {setAgreeTermsAndPolicy(true); setTosAndPrivatePolicyVisible(false)}}
                                ></Button>
                               </Flex>
                            </Flex>
                        </div>
                    }
                    {signUpLoading && <Loader width="50%" height="50%" transform="-50%, -100%"/>}

                    {(signUpStatus === "") && 
                        <Signup overrides={signupOverrides}></Signup>
                    }
                    {(signUpStatus === "Awaiting Verification") && 
                        <Verification email={email}></Verification>
                    }

                    {(signUpStatus === "Verified") && 
                        <Success email={email} password={password}/>
                    }   
                </div>
            </div>
        </div>
    )
};

export default SignUp;