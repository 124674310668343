import '../account.css';
import { ResetPasswordEmail } from '../../../ui-components';
import { ResetPasswordSuccess } from '../../../ui-components';
import {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { forgotPassword as forgotPasswordForUser } from '../../../actions/reducers/reducers';
import ResetPassword from './ResetPassword';
import { resetState } from '../../../actions/action';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../utils/loader/Loader';
import GetDiscovered from '../GetDiscovered/GetDiscovered';


const selectForgotPasswordLoading = (state) => {return state.account.forgotPasswordLoading};
const selectForgotPasswordError = (state) => {return state.account.forgotPasswordError};
const selectChangePasswordStatus = (state) => {return state.account.changePasswordStatus};


function ForgotPassword() {
    const navigate = useNavigate();

    const forgotPasswordLoading = useSelector((state) => selectForgotPasswordLoading(state));
    const forgotPasswordError = useSelector((state) => selectForgotPasswordError(state));
    const changePasswordStatus = useSelector((state) => selectChangePasswordStatus(state));

    const [resetPasswordEmailOverrides, setResetPasswordEmailOverrides] = useState({});
    const [resetPasswordSuccessOverrides, setResetPasswordSuccessOverrides] = useState({});

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const dispatch = useDispatch();

    const navigateToSignIn = useCallback(() => {
        dispatch(resetState());
        navigate('/signin', {state: {email: email}});
    }, [navigate, dispatch, email]);

    const navigateBackToSignIn = useCallback(() => {
        dispatch(resetState());
        navigate('/signin')
    }, [navigate, dispatch]);

    useEffect(() => {
        const newResetPasswordSuccessOverrides = {
            ...resetPasswordSuccessOverrides,
            "Button": {
                onClick: navigateToSignIn
            },
            "email": email,
        };

        if (JSON.stringify(newResetPasswordSuccessOverrides) !== JSON.stringify(resetPasswordSuccessOverrides)) {
            setResetPasswordSuccessOverrides(newResetPasswordSuccessOverrides);
        }
    }, [resetPasswordSuccessOverrides, navigateToSignIn, email]);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const validateEmail = useCallback(() => {
        if (email.trim() === '') {
            setEmailError('Email address is required.')
            return false;
        }
        setEmailError('');
        return true;
    }, [email]);

    useEffect(() => {
        if (forgotPasswordError) {
            if (forgotPasswordError.status === 429) {
                setEmailError('Attempted too many requests. Please try again later.')
            }

            else if (forgotPasswordError.status === 404) {
                setEmailError('Could not find verified user with this email.')
            }   
        }
    }, [forgotPasswordError]);

    const handleForgotPassword = useCallback(async (event) => {
        event.preventDefault();

        if (validateEmail(email)) {
            const forgotPasswordRequestBody = {
                email: email
            };
            forgotPasswordForUser(dispatch, forgotPasswordRequestBody);

        }
    }, [email, validateEmail, dispatch]);

    useEffect(() => {
        const newResetPasswordEmailOverrides = {
            ...resetPasswordEmailOverrides,

            ResetPasswordEmail: {
                height: "100vh",
                width: "50vw",
            },
            
            "TextField": {
                value: email,
                onChange: handleEmailChange,
                hasError: emailError ? true : false,
                errorMessage: emailError
            },
    
            "Button": {
                onClick: handleForgotPassword
            },
    
            "Go back to Login": {
                children: (
                    // <>
                    //     Go back to{' '}
                    //     <Link to="/signin" style={{ fontWeight: '600', color: 'rgba(49,91,155,1)', textDecoration: 'none' }}>
                    //         Login
                    //     </Link>
                    // </>

                    <>
                        Go back to{' '}
                        <span
                            onClick={navigateBackToSignIn}
                            style={{ fontWeight: '600', color: 'rgba(49,91,155,1)', textDecoration: 'none', cursor: 'pointer' }}
                        >
                            Login
                        </span>
                    </>      
                ),
            },
        }

        if (JSON.stringify(newResetPasswordEmailOverrides) !== JSON.stringify(resetPasswordEmailOverrides)) {
            setResetPasswordEmailOverrides(newResetPasswordEmailOverrides);
        }
    }, [resetPasswordEmailOverrides, email, emailError, handleForgotPassword, navigateBackToSignIn]);

    return (
        <div className="container">
            <div className="side-by-side">
                <GetDiscovered></GetDiscovered>

                    
                <div className="form-container">
                    {(changePasswordStatus === "Success") && 
                        <ResetPasswordSuccess overrides={resetPasswordSuccessOverrides}/>
                    }  
           
                
             
                    {forgotPasswordLoading && <Loader width="10rem" height="10rem" transform="-50%, -100%"/>}
        
                
                
                   
                    {(!changePasswordStatus) &&
                        <ResetPasswordEmail overrides={resetPasswordEmailOverrides}></ResetPasswordEmail>
                    }
              

                
              
                    {(changePasswordStatus === "Awaiting Verification") && 
                        <ResetPassword email={email} onForgotPassword={handleForgotPassword}/>
                    }
                </div>


                {/* <ResetPasswordMain></ResetPasswordMain> */}
            </div>
        </div>
    )
};

export default ForgotPassword;