/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
export default function Component2(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="69px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="120px 290px 120px 290px"
      backgroundColor="rgba(246,246,246,1)"
      {...getOverrideProps(overrides, "Component2")}
      {...rest}
    >
      <Flex
        gap="64px"
        direction="column"
        width="861px"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 32px 0px 32px"
        {...getOverrideProps(overrides, "Text")}
      >
        <Flex
          gap="16px"
          direction="column"
          width="797px"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 1160464422")}
        >
          <Text
            fontFamily="Inter"
            fontSize="40px"
            fontWeight="700"
            color="rgba(16,38,65,1)"
            lineHeight="48px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="-0.25px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Personalized Job Match"
            {...getOverrideProps(overrides, "Personalized Job Match")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="18px"
            fontWeight="400"
            color="rgba(30,27,27,1)"
            lineHeight="30.600000381469727px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Instead of spending hours scrolling through job ads, Ikimy's algorithm will do the work for you. It matches your profile with the job requirements provided by employers on the platform. "
            {...getOverrideProps(
              overrides,
              "Instead of spending hours scrolling through job ads, Ikimy's algorithm will do the work for you. It matches your profile with the job requirements provided by employers on the platform."
            )}
          ></Text>
        </Flex>
        <Image
          width="889px"
          height="307.5px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          {...getOverrideProps(overrides, "image 1")}
        ></Image>
      </Flex>
    </Flex>
  );
}
