/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon, View } from "@aws-amplify/ui-react";
export default function IconCheck(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="24px"
      height="24px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "IconCheck")}
      {...rest}
    >
      <View
        padding="0px 0px 0px 0px"
        width="22px"
        height="22px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="4.17%"
        bottom="4.17%"
        left="4.17%"
        right="4.17%"
        {...getOverrideProps(overrides, "Group")}
      >
        <Icon
          width="11.31px"
          height="8.49px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 11.31399917602539,
            height: 8.48699951171875,
          }}
          paths={[
            {
              d: "M4.243 8.487L0 4.243L1.414 2.829L4.243 5.657L9.899 0L11.314 1.415L4.243 8.485L4.243 8.487Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="31.03%"
          bottom="30.39%"
          left="22.73%"
          right="25.85%"
          {...getOverrideProps(overrides, "Vector38493494")}
        ></Icon>
        <Icon
          width="22px"
          height="22px"
          viewBox={{ minX: 0, minY: 0, width: 22, height: 22 }}
          paths={[
            {
              d: "M0 11C0 4.925 4.925 0 11 0C17.075 0 22 4.925 22 11C22 17.075 17.075 22 11 22C4.925 22 0 17.075 0 11ZM11 20C9.8181 20 8.64778 19.7672 7.55585 19.3149C6.46392 18.8626 5.47177 18.1997 4.63604 17.364C3.80031 16.5282 3.13738 15.5361 2.68508 14.4442C2.23279 13.3522 2 12.1819 2 11C2 9.8181 2.23279 8.64778 2.68508 7.55585C3.13738 6.46392 3.80031 5.47177 4.63604 4.63604C5.47177 3.80031 6.46392 3.13738 7.55585 2.68508C8.64778 2.23279 9.8181 2 11 2C13.3869 2 15.6761 2.94821 17.364 4.63604C19.0518 6.32387 20 8.61305 20 11C20 13.3869 19.0518 15.6761 17.364 17.364C15.6761 19.0518 13.3869 20 11 20Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "evenodd",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="0%"
          {...getOverrideProps(overrides, "Vector38493495")}
        ></Icon>
      </View>
    </View>
  );
}
