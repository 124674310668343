/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Icon, Image, Text, View } from "@aws-amplify/ui-react";
export default function FounderInsight(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="82px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="120px 133px 120px 133px"
      backgroundColor="rgba(34,66,114,1)"
      {...getOverrideProps(overrides, "FounderInsight")}
      {...rest}
    >
      <Flex
        gap="55px"
        direction="column"
        width="598px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 36523")}
      >
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Title38561865")}
        >
          <Text
            fontFamily="Inter"
            fontSize="18px"
            fontWeight="700"
            color="rgba(215,183,101,1)"
            textTransform="uppercase"
            lineHeight="28px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="1.29px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Founder insight"
            {...getOverrideProps(overrides, "Founder insight")}
          ></Text>
        </Flex>
        <Flex
          gap="32px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 1160464415")}
        >
          <Text
            fontFamily="Inter"
            fontSize="28px"
            fontWeight="400"
            color="rgba(255,255,255,1)"
            lineHeight="47.60000228881836px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="You don't have to love what you do, but to be more effective and satisfied overall, you shouldn't hate your work, either. That is why our tagline is &quot;Enjoy What You Do&quot;"
            {...getOverrideProps(
              overrides,
              "You don't have to love what you do, but to be more effective and satisfied overall, you shouldn't hate your work, either. That is why our tagline is \"Enjoy What You Do\""
            )}
          ></Text>
          <Flex
            gap="8px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 1160464414")}
          >
            <Text
              fontFamily="Inter"
              fontSize="36px"
              fontWeight="700"
              color="rgba(255,255,255,1)"
              lineHeight="54px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="429px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Dona Baker"
              {...getOverrideProps(overrides, "Dona Baker")}
            ></Text>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Title38561871")}
            >
              <Text
                fontFamily="Inter"
                fontSize="18px"
                fontWeight="700"
                color="rgba(255,255,255,1)"
                textTransform="uppercase"
                lineHeight="28px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="1.29px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Co-Founder, Rhealize"
                {...getOverrideProps(overrides, "Co-Founder, Rhealize")}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <View
        width="501px"
        height="500px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 1160464416")}
      >
        <View
          padding="0px 0px 0px 0px"
          width="501px"
          height="500px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="0px"
          {...getOverrideProps(overrides, "Group 1160464142")}
        >
          <Icon
            width="437px"
            height="437px"
            viewBox={{ minX: 0, minY: 0, width: 437, height: 437 }}
            paths={[
              {
                d: "M437 218.5C437 339.174 339.174 437 218.5 437C97.8258 437 0 339.174 0 218.5C0 97.8258 97.8258 0 218.5 0C339.174 0 437 97.8258 437 218.5Z",
                fill: "rgba(205,164,60,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="63px"
            left="32px"
            {...getOverrideProps(overrides, "Ellipse 236")}
          ></Icon>
          <Image
            width="500px"
            height="500px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            left="0px"
            borderRadius="1000px"
            padding="0px 0px 0px 0px"
            objectFit="unset"
            {...getOverrideProps(overrides, "unsplash:6SlQ22nMzGs")}
          ></Image>
          <Icon
            width="500px"
            height="500px"
            viewBox={{ minX: 0, minY: 0, width: 500, height: 500 }}
            paths={[
              {
                d: "M250 496C385.862 496 496 385.862 496 250C496 114.138 385.862 4 250 4C114.138 4 4 114.138 4 250C4 385.862 114.138 496 250 496ZM250 500C388.071 500 500 388.071 500 250C500 111.929 388.071 0 250 0C111.929 0 0 111.929 0 250C0 388.071 111.929 500 250 500Z",
                fill: "rgba(205,164,60,1)",
                fillRule: "evenodd",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            left="1px"
            {...getOverrideProps(overrides, "Ellipse 237 (Stroke)")}
          ></Icon>
        </View>
      </View>
    </Flex>
  );
}
