import { SIGN_UP_ERROR, SIGN_UP_REQUEST, SIGN_UP_AWAITING_VERIFICATION, SIGN_UP_SUCCESS, CONFIRM_SIGN_UP_REQUEST, CONFIRM_SIGN_UP_SUCCESS, CONFIRM_SIGN_UP_ERROR, RESEND_VERIFICATION_REQUEST, RESEND_VERIFICATION_SUCCESS, RESEND_VERIFICATION_ERROR, FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_ERROR, RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR, SIGN_IN_REQUEST, SIGN_IN_ERROR, SIGN_IN_SUCCESS, NAVIGATE_USER_TO_ACCOUNT_VERIFICATION, RESET_STATE} from "../actionTypes";
import { confirmSignUpRequest, forgotPasswordRequest, resendVerificationRequest, resetPasswordRequest, signInRequest, signUpRequest } from "../fetchData";

let initialState = {
    signUpLoading: false,
    signUpError: null,
    signUpStatus: "" ,
    
    confirmSignUpLoading: false,
    confirmSignUpError: null,
    
    resendVerificationLoading: false,
    resendVerificationError: null,

    forgotPasswordLoading: false,
    forgotPasswordError: null,
    changePasswordStatus: "",

    resetPasswordLoading: false,
    resetPasswordError: null,

    signInLoading: false,
    signInError: null,
    token: "",
    tokenExpirationTime: null

};

function reducer(state = initialState, action) {
    switch (action.type) {
        case SIGN_UP_REQUEST:
            return {
                ...state,
                signUpLoading: true,
                signUpError: null,
                signUpStatus: ""
            };
        
        case SIGN_UP_SUCCESS:
            return {
                ...state,
                signUpLoading: false,
                signUpStatus: "Awaiting Verification"
            };
        
        case SIGN_UP_AWAITING_VERIFICATION:
            return {
                ...state,
                signUpLoading: false,
                signUpStatus:  "Awaiting Verification"
            }
        
        case SIGN_UP_ERROR:
            return {
                ...state,
                signUpLoading: false,
                signUpError: action.payload
            };
        
        case CONFIRM_SIGN_UP_REQUEST:
            return {
                ...state,
                confirmSignUpLoading: true,
                confirmSignUpError: null,
            };

        case CONFIRM_SIGN_UP_SUCCESS:
            return {
                ...state,
                confirmSignUpLoading: false,
                signUpStatus: "Verified"
            };

        case CONFIRM_SIGN_UP_ERROR:
            return {
                ...state,
                confirmSignUpLoading: false,
                confirmSignUpError: action.payload
            };

        case RESEND_VERIFICATION_REQUEST:
            return {
                ...state,
                resendVerificationLoading: true,
                resendVerificationError: null,
            };
    
        case RESEND_VERIFICATION_SUCCESS:
            return {
                ...state,
                resendVerificationLoading: false,
            };
    
        case RESEND_VERIFICATION_ERROR:
            return {
                ...state,
                confirmSignUpLoading: false,
                confirmSignUpError: action.payload
            };
            
        case FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                forgotPasswordLoading: true,
                forgotPasswordError: null,
                resetPasswordError: null,
            };
            
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                forgotPasswordLoading: false,
                changePasswordStatus: "Awaiting Verification"
            };
            
        case FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                forgotPasswordLoading: false,
                forgotPasswordError: action.payload
            };
            
        case RESET_PASSWORD_REQUEST:
            return {
                ...state,
                resetPasswordLoading: true,
                resetPasswordError: null
            };
                
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordLoading: false,
                changePasswordStatus: "Success"
            };
                
        case RESET_PASSWORD_ERROR:
            return {
                ...state,
                resetPasswordLoading: false,
                resetPasswordError: action.payload
            };

        case SIGN_IN_REQUEST:
            return {
                ...state,
                signInLoading: true,
                signInError: null
            };
                    
        case SIGN_IN_SUCCESS:
            return {
                ...state,
                signInLoading: false,
                token: action.payload.token,
                tokenExpirationTime: action.payload.tokenExpirationTime
            };
                    
        case SIGN_IN_ERROR:
            return {
                ...state,
                signInLoading: false,
                signInError: action.payload
            };
        
        case NAVIGATE_USER_TO_ACCOUNT_VERIFICATION:
            return {
                ...state,
                signInError: null,
                signUpStatus: "Awaiting Verification"
            };
        
        case RESET_STATE:
            return {
                ...state,
                signUpLoading: false,
                signUpError: null,
                signUpStatus: "" ,
                
                confirmSignUpLoading: false,
                confirmSignUpError: null,
                
                resendVerificationLoading: false,
                resendVerificationError: null,
            
                forgotPasswordLoading: false,
                forgotPasswordError: null,
                changePasswordStatus: "",
            
                resetPasswordLoading: false,
                resetPasswordError: null,
            
                signInLoading: false,
                signInError: null,
                token: "",
                tokenExpirationTime: null
            };
        
        default:
            return state;
    }
}

export function signUp(dispatch, signUpRequestModel) {
    signUpRequest(dispatch, signUpRequestModel);
}

export function confirmSignUp(dispatch, confirmSignUpRequestModel) {
    confirmSignUpRequest(dispatch, confirmSignUpRequestModel);
}

export function resendVerification(dispatch, resendVerificationRequestModel) {
    resendVerificationRequest(dispatch, resendVerificationRequestModel);
}

export function forgotPassword(dispatch, forgotPasswordRequestModel) {
    forgotPasswordRequest(dispatch, forgotPasswordRequestModel);
}

export function resetPassword(dispatch, resetPasswordRequestModel) {
    resetPasswordRequest(dispatch, resetPasswordRequestModel);
}

export function signIn(dispatch, signInRequestModel) {
    signInRequest(dispatch, signInRequestModel);
}

export default reducer;