import '../account.css';
import { Signin } from '../../../ui-components';
import { useState, useCallback, useEffect } from 'react';
import { signIn as signInUser} from '../../../actions/reducers/reducers';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { navigateUserToAccountVerification } from '../../../actions/action';
import { resetState } from '../../../actions/action';
import { useLocation } from 'react-router-dom';
import Loader from '../../../utils/loader/Loader';
import GetDiscovered from '../GetDiscovered/GetDiscovered';
import Error from '../../../utils/error/Error';
import { error } from '../../../utils/error/ErrorMessage';

const selectSignInLoading = (state) => {return state.account.signInLoading};
const selectSignInLError = (state) => {return state.account.signInError};
const selectToken = (state) => {return state.account.token};
const selectTokenExpirationTime = (state) => {return state.account.tokenExpirationTime};

function SignIn() {

    const [errors, setErrors] = useState([]);

    const [signInOverrides, setSignInOverrides] = useState({});
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const navigate = useNavigate();

    const signInLoading = useSelector((state) => selectSignInLoading(state));
    const signInError = useSelector((state) => selectSignInLError(state));
    const token = useSelector((state) => selectToken(state));
    const tokenExpirationTime = useSelector((state) => selectTokenExpirationTime(state));

    const dispatch = useDispatch();

    const removeError = index => {
        setErrors(errors => errors.filter((_, i) => i !== index));
    }

    const addError = newError => {
        setErrors(errors => {
            if (!errors.some(error => error.field === newError.field && error.message === newError.message)) {
                return [...errors, newError];
            }

            return errors;
        });
    }

    useEffect(() => {
        if (errors.length) {
            const timer = setTimeout(() => {
                removeError(0);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [errors]);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const location = useLocation();
    
    useEffect(() => {
        if (location.state?.email) {
            setEmail(location.state.email);
        }
        if (location.state?.password) {
            setPassword(location.state.password);
        }
    }, [location]);

    useEffect(() => {
        if (signInError) {
            if (signInError.status === 401 && signInError.message === "Invalid login credentials") {
                setEmailError("Invalid credentials");
                setPasswordError("Invalid credentials");
                addError(error("email", "Invalid credentials"));
                addError(error("password", ""));
            }

            else if (signInError.status === 429) {
                setEmailError("Too many sign in attempts. Please try again later.");
                addError(error("email", "Too many sign in attempts. Please try again later."));
            }

            // Don't expect this to happen 
            else if (signInError.status === 403) {
                setPasswordError("Password reset required");
                addError(error("email", "Too many sign in attempts. Please try again later."));
            }

            // Account is still in verification state, navigate to sign up verification 
            else if (signInError.status === 401) {
                dispatch(navigateUserToAccountVerification());
                navigate('/signup', {state: {email: email}});
            }
        }
    }, [signInError, dispatch, email, navigate])

    useEffect(() => {
        if (token) {
            if ((new Date().getTime()) < tokenExpirationTime) {
                const queryParams = new URLSearchParams(location.search);
                const redirectUrl = queryParams.get('redirect_url');
                localStorage.setItem('token', token);
                if (redirectUrl) {
                    window.location.href = `${redirectUrl}?token=${token}`;
                }
                else {
                    window.location.href = '/dashboard';
                }
               
            }
        }
    }, [token, tokenExpirationTime, location.search]);

    const validateEmail = useCallback(() => {
        if (email.trim() === '') {
            setEmailError("Please enter the email registered with your account");
            addError(error("email", "Please enter the email registered with your account"));
            return false;
        }
        setEmailError('');
        return true;
    }, [email]);

    const validatePassword = useCallback(() => {
        if (password.trim() === '') {
            setPasswordError("Please enter your password");
            addError(error("password", "Please enter your password"));
            return false;
        }
        setPasswordError('');
        return true;
    }, [password]);

    const handleSignIn = useCallback(async (event) => {
        event.preventDefault();

        const validations = [
            validateEmail,
            validatePassword,
        ];

        const allValid = validations.map(validation => validation()).every(result => result)

        if (allValid) {
            const signInRequestBody = {
                email: email,
                password: password
            };
            signInUser(dispatch, signInRequestBody);
        }
    }, [email, password, validateEmail, validatePassword, dispatch]);

    const navigateToForgotPassword = useCallback(() => {
        dispatch(resetState());
        navigate('/forgotpassword')
    }, [navigate, dispatch]);

    const navigateToSignUp = useCallback(() => {
        dispatch(resetState());
        navigate('/signup')
    }, [navigate, dispatch]);


    useEffect(() => {
        const newSignInOverrides = {
            ...signInOverrides,

            Signin: {
                height: "100vh",
                width: "50vw",
            },


            "TextField": {
                value: email,
                onChange: handleEmailChange,
                hasError: emailError ? true : false
            },

            "PasswordField": {
                value: password,
                onChange: handlePasswordChange,
                hasError: passwordError ? true : false
            },

            "Button": {
                onClick: handleSignIn
            },
            
            "Not registered? Create an account.": {
                children: (
                    // <>
                    //     Not Registered?{' '}
                    //     <Link to="/signup" style={{ fontWeight: '600', color: 'rgba(49,91,155,1)', textDecoration: 'none' }}>
                    //         Create an account.
                    //     </Link>
                    // </>

                    <span
                        onClick={navigateToSignUp}
                        style={{ fontWeight: '600', color: 'rgba(49,91,155,1)', textDecoration: 'none', cursor: 'pointer' }}
                    >
                        Create an account.
                    </span>
                ),
            },
    
            "Forgot Password?": {
                children: (
                    // <>
                    //     <Link to="/forgotpassword" style={{ fontWeight: '600', color: 'rgba(49,91,155,1)', textDecoration: 'none' }}>
                    //         Forgot Password?
                    //     </Link>
                    // </>

                    
                    <span
                        onClick={navigateToForgotPassword}
                        style={{ fontWeight: '600', color: 'rgba(49,91,155,1)', textDecoration: 'none', cursor: 'pointer' }}
                    >
                        Forgot Password?
                    </span>
                    
                )
            }
        };

        if (JSON.stringify(newSignInOverrides) !== JSON.stringify(signInOverrides)) {
            setSignInOverrides(newSignInOverrides);
        }
    }, [signInOverrides, email, emailError, password, passwordError, handleSignIn, navigateToForgotPassword, navigateToSignUp]);

    // const loaderClass = signInLoading ? 'loader-container' : 'loader-container loader-hidden';

    return (
        <div className="container">
            <div className="side-by-side">
                <GetDiscovered></GetDiscovered>
                <div className="form-container">
                  
                    <Error errors={errors} onClose={removeError}></Error>
                  
                    
                    {signInLoading &&
                      <Loader width="10rem" height="10rem" transform="-50%, -100%"/>
                    }
                    {!token && <Signin overrides={signInOverrides}/> }
               
                </div>
                
                
            </div>
        </div>
    )
};

export default SignIn;