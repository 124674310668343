/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import AppLogo from "./AppLogo";
import TopNavMenuItem from "./TopNavMenuItem";
import { Button, Flex } from "@aws-amplify/ui-react";
import Avartar from "./Avartar";
export default function NavBarDefaultNo(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="34px"
      direction="row"
      width="1287px"
      height="105px"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="10px 32px 10px 32px"
      backgroundColor="rgba(244,248,252,1)"
      {...getOverrideProps(overrides, "NavBarDefaultNo")}
      {...rest}
    >
      <AppLogo
        display="flex"
        gap="2.5490198135375977px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="4.078431606292725px 4.078431606292725px 4.078431606292725px 4.078431606292725px"
        darkMode="False"
        {...getOverrideProps(overrides, "AppLogo")}
      ></AppLogo>
      <Flex
        gap="40px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "NavMenu")}
      >
        <TopNavMenuItem
          display="flex"
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "TopNavMenuItem38493544")}
        ></TopNavMenuItem>
        <TopNavMenuItem
          display="flex"
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "TopNavMenuItem38493545")}
        ></TopNavMenuItem>
        <TopNavMenuItem
          display="flex"
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "TopNavMenuItem38493546")}
        ></TopNavMenuItem>
        <TopNavMenuItem
          display="flex"
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "TopNavMenuItem38493547")}
        ></TopNavMenuItem>
        <TopNavMenuItem
          display="flex"
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "TopNavMenuItem38493548")}
        ></TopNavMenuItem>
      </Flex>
      <Flex
        gap="16px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 1160464103")}
      >
        <TopNavMenuItem
          display="flex"
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "TopNavMenuItem38493550")}
        ></TopNavMenuItem>
        <Button
          width="unset"
          height="unset"
          shrink="0"
          size="default"
          isDisabled={false}
          variation="primary"
          children="Signup"
          {...getOverrideProps(overrides, "Button")}
        ></Button>
        <Avartar
          width="40px"
          height="40px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Avartar")}
        ></Avartar>
      </Flex>
    </Flex>
  );
}
