import { SIGN_UP_ERROR, SIGN_UP_AWAITING_VERIFICATION, SIGN_UP_REQUEST, SIGN_UP_SUCCESS, CONFIRM_SIGN_UP_REQUEST, CONFIRM_SIGN_UP_SUCCESS, CONFIRM_SIGN_UP_ERROR, RESEND_VERIFICATION_REQUEST, RESEND_VERIFICATION_SUCCESS, RESEND_VERIFICATION_ERROR, FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_ERROR, RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR, SIGN_IN_REQUEST, SIGN_IN_SUCCESS, SIGN_IN_ERROR, NAVIGATE_USER_TO_ACCOUNT_VERIFICATION, RESET_STATE } from "./actionTypes";

export function signUpRequest() {
    return {
        type: SIGN_UP_REQUEST
    };
}

export function signUpSuccess(data) {
    return {
        type: SIGN_UP_SUCCESS,
        payload: data
    };
}

export function signUpAwaitingVerification(error) {
    return {
        type: SIGN_UP_AWAITING_VERIFICATION,
        payload: error
    };
}


export function signUpError(error) {
    return {
        type: SIGN_UP_ERROR,
        payload: error
    };
}

export function confirmSignUpRequest() {
    return {
        type: CONFIRM_SIGN_UP_REQUEST
    };
}

export function confirmSignUpSuccess(data) {
    return {
        type: CONFIRM_SIGN_UP_SUCCESS,
        payload: data
    };
}

export function confirmSignUpError(error) {
    return {
        type: CONFIRM_SIGN_UP_ERROR,
        payload: error
    };
}

export function resendVerificationRequest() {
    return {
        type: RESEND_VERIFICATION_REQUEST
    };
}

export function resendVerificationSuccess(data) {
    return {
        type: RESEND_VERIFICATION_SUCCESS,
        payload: data
    };
}

export function resendVerificationError(error) {
    return {
        type: RESEND_VERIFICATION_ERROR,
        payload: error
    };
}

export function forgotPasswordRequest() {
    return {
        type: FORGOT_PASSWORD_REQUEST
    };
}

export function forgotPasswordSuccess(data) {
    return {
        type: FORGOT_PASSWORD_SUCCESS,
        payload: data
    };
}

export function forgotPasswordError(error) {
    return {
        type: FORGOT_PASSWORD_ERROR,
        payload: error
    };
}

export function resetPasswordRequest() {
    return {
        type: RESET_PASSWORD_REQUEST
    };
}

export function resetPasswordSuccess(data) {
    return {
        type: RESET_PASSWORD_SUCCESS,
        payload: data
    };
}

export function resetPasswordError(error) {
    return {
        type: RESET_PASSWORD_ERROR,
        payload: error
    };
}

export function signInRequest() {
    return {
        type: SIGN_IN_REQUEST
    };
}

export function signInSuccess(data) {
    const tokenLifespanInMilliseconds = Number(data.expiresIn) * 60000; 
    const tokenExpirationTime = new Date(new Date().getTime() + tokenLifespanInMilliseconds);
    return {
        type: SIGN_IN_SUCCESS,
        payload: {
            token: data.accessToken,
            tokenExpirationTime: tokenExpirationTime.getTime()
        }
    };
}

export function signInError(error) {
    return {
        type: SIGN_IN_ERROR,
        payload: error
    };
}

export function navigateUserToAccountVerification() {
    return {
        type: NAVIGATE_USER_TO_ACCOUNT_VERIFICATION
    };
}

export function resetState() {
    return {
        type: RESET_STATE
    };
}