import { SignupSuccess } from '../../../ui-components';
import { useCallback, useMemo } from 'react';
import {useDispatch} from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { resetState } from '../../../actions/action';

function Success({email, password}) {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const navigateToSignIn = useCallback(() => {
        dispatch(resetState());
        navigate('/signin', {state: {email: email, password: password}});
    }, [navigate, dispatch, email, password]);

    const signupSuccessOverrides = useMemo(() => {
        return {
           
            "Signup Successful!": {
                children: "Sign Up Successful!"
            },

            "Button": {
                onClick: navigateToSignIn,
                children: "Sign In"
            },

            "email": email,
            "password": password
        };

    }, [navigateToSignIn, email, password]);
    
    return (
        <SignupSuccess overrides={signupSuccessOverrides} ></SignupSuccess>
    )
};

export default Success;

