/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import AppLogo from "./AppLogo";
import { Button, Flex, Text, TextField } from "@aws-amplify/ui-react";
export default function ResetPasswordEmail(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="24px"
      direction="column"
      width="771px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="64px 60px 64px 60px"
      {...getOverrideProps(overrides, "ResetPasswordEmail")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 1160464352")}
      >
        <AppLogo
          display="flex"
          gap="4.2016801834106445px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="6.722688674926758px 6.722688674926758px 6.722688674926758px 6.722688674926758px"
          darkMode="False"
          {...getOverrideProps(overrides, "AppLogo")}
        ></AppLogo>
      </Flex>
      <Flex
        gap="23px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 914")}
      >
        <Text
          fontFamily="Inter"
          fontSize="20px"
          fontWeight="700"
          color="rgba(34,59,85,1)"
          lineHeight="30px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="644.54px"
          height="26px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Reset your password"
          {...getOverrideProps(overrides, "We emailed you!")}
        ></Text>
        <Flex
          gap="32px"
          direction="column"
          width="unset"
          height="251px"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Email confirmation text")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(34,59,85,1)"
            lineHeight="27.200000762939453px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Please enter the email address you'd like your password reset information sent to."
            {...getOverrideProps(
              overrides,
              "Your code is on the way. To log in, enter the code we emailed to damitimo@yahoo.com. It may take a minute to arrive."
            )}
          ></Text>
          <Flex
            gap="24px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 1160464405")}
          >
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 1160464354")}
            >
              <TextField
                width="unset"
                height="unset"
                label="E-mail *"
                placeholder="Enter Address"
                shrink="0"
                alignSelf="stretch"
                size="default"
                isDisabled={false}
                labelHidden={false}
                variation="default"
                {...getOverrideProps(overrides, "TextField")}
              ></TextField>
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 1160464355")}
              >
                <Button
                  width="unset"
                  height="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  size="default"
                  isDisabled={false}
                  variation="primary"
                  children="Reset your password"
                  {...getOverrideProps(overrides, "Button")}
                ></Button>
              </Flex>
            </Flex>
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(25,25,25,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Go back to Login"
              {...getOverrideProps(overrides, "Go back to Login")}
            ></Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
