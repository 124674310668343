import './App.css';
import SignIn from '../pages/Account/SignIn/SignIn';
import SignUp from '../pages/Account/SignUp/SignUp';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ForgotPassword from '../pages/Account/ForgotPassword/ForgotPassword';
import Home from '../pages/Home/Home';
//import '@aws-amplify/ui-react/styles.css';
//import '@aws-amplify/ui-react/styles.layer.css';
//import '@fontsource/inter/variable.css';
//import { AppLogo } from './ui-components';
import MobileSupport from '../utils/mobilesupport/MobileSupport';
import { isMobileDevice } from '../utils/mobilesupport/MobileSupport';

function AppWrapper() {
  return (
      <Router>
        <App/>
      </Router>
  )
};

function App() {

  if (isMobileDevice()) {
    return <MobileSupport></MobileSupport>
  }
  
  return (
      <Routes>
          <Route path="/" element={<Navigate to="/signin" replace />} />
          <Route path="/signin" element={<SignIn/>}/>
          <Route path="/signup" element={<SignUp/>}/>
          <Route path="/forgotpassword" element={<ForgotPassword/>}/>\
          <Route path="/home" element={<Home/>}/>
      </Routes>
  );
}

export default AppWrapper;


