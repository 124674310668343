import SignupLeftSection from '../../../ui-components/SignupLeftSection';
import "./GetDiscovered.css";

const GetDiscovered = () => {
    const signupLeftSectionOverrides = {
        
        SignupLeftSection: {
            height: "100vh",
            width: "50vw",
        },
        IntroLayoutDarkMode: {
            height: "100vh",
            width: "50vw",
        },
        "Fill 90": {
            //boxSizing: "border-box",
            position: "absolute",
            right: "1.451px",
            bottom: "-33.79px",
            width: "221.632px",
            height: "49.631px",
            transform: "rotate(6deg)",
            viewBox: {
                minX: "0",
                minY: "0",
                width: "233.632px",
                height: "39.631px"
            },
            fill: "#CCA43B",
            stroke: "black"
        },

    };

    return (
        <SignupLeftSection overrides={signupLeftSectionOverrides} />
    )
}

export default GetDiscovered;