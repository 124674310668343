/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import AppLogo from "./AppLogo";
import { Button, Flex, Text, TextField } from "@aws-amplify/ui-react";
export default function SigninVerification(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="24px"
      direction="column"
      width="771px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="64px 60px 64px 60px"
      {...getOverrideProps(overrides, "SigninVerification")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 1160464352")}
      >
        <AppLogo
          display="flex"
          gap="4.2016801834106445px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="6.722688674926758px 6.722688674926758px 6.722688674926758px 6.722688674926758px"
          darkMode="False"
          {...getOverrideProps(overrides, "AppLogo")}
        ></AppLogo>
      </Flex>
      <Text
        fontFamily="Inter"
        fontSize="32px"
        fontWeight="700"
        color="rgba(48,64,80,1)"
        lineHeight="48px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="We emailed you!"
        {...getOverrideProps(overrides, "We emailed you!")}
      ></Text>
      <Flex
        gap="23px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 914")}
      >
        <Flex
          gap="36px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Email confirmation text")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(34,59,85,1)"
            lineHeight="27.200000762939453px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Your code is on the way. To log in, enter the code we emailed to damitimo@yahoo.com. It may take a minute to arrive."
            {...getOverrideProps(
              overrides,
              "Your code is on the way. To log in, enter the code we emailed to damitimo@yahoo.com. It may take a minute to arrive."
            )}
          ></Text>
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 1160464354")}
          >
            <TextField
              width="unset"
              height="unset"
              label="E-mail *"
              placeholder="Enter Verification Code"
              shrink="0"
              alignSelf="stretch"
              size="default"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              {...getOverrideProps(overrides, "TextField")}
            ></TextField>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 1160464355")}
            >
              <Button
                width="unset"
                height="unset"
                grow="1"
                shrink="1"
                basis="0"
                size="default"
                isDisabled={false}
                variation="primary"
                children="Confirm"
                {...getOverrideProps(overrides, "Button38493582")}
              ></Button>
              <Button
                width="unset"
                height="unset"
                border="1px SOLID rgba(49,91,155,1)"
                grow="1"
                shrink="1"
                basis="0"
                size="default"
                isDisabled={false}
                variation="default"
                children="Resend Code"
                {...getOverrideProps(overrides, "Button38493583")}
              ></Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
