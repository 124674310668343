import { HeroSection, NavBarDefaultNo, ProcessSection, Component2, CareerGuidance, LearningResources, FounderInsight, CTA, FAQ, Footer} from "../../ui-components";
import { useState, useEffect } from "react";
import IkimyHeroImage from '../../assets/images/home/IkimyHeroImage.jpg';
import Step1 from '../../assets/images/home/Step1.svg';
import Step2 from '../../assets/images/home/Step2.svg';
import Step3 from '../../assets/images/home/Step3.svg';
import PersonalizedJobMatch from '../../assets/images/home/PersonalizedJobMatch.svg';
import WorkLifeGoals from '../../assets/images/home/WorkLifeGoals.png';
import WorkValueSorter from '../../assets/images/home/WorkValueSorter.png';
import HappyCandidate from '../../assets/images/home/HappyCandidate.jpg';
import Learning from '../../assets/images/home/Learning.svg';
import Graph from '../../assets/images/home/Graph.svg';
import DonaBaker from '../../assets/images/home/DonaBaker.png';
import FindYourIdealJob from '../../assets/images/home/FindYourIdealJob.png';
import "./Home.css";

// TopNavMenuItem38493544?: TopNavMenuItemProps;
// TopNavMenuItem38493545?: TopNavMenuItemProps;
// TopNavMenuItem38493546?: TopNavMenuItemProps;
// TopNavMenuItem38493547?: TopNavMenuItemProps;
// TopNavMenuItem38493548?: TopNavMenuItemProps;
// "Frame 1160464103"?: PrimitiveOverrideProps<FlexProps>;
// TopNavMenuItem38493550?: TopNavMenuItemProps;
// Button?: PrimitiveOverrideProps<ButtonProps>;
// Avartar?: AvartarProps;

const redirectToLogin = () => {
    window.location.href = "/signin"
}

const navigateToDashboard = () => {
    window.location.href = "/dashboard";
}

const Home = () => {
    const [navBarDefaultNoOverrides, setNavBarDefaultNoOverrides] = useState({});

    useEffect(() => {
        const newNavBarDefaultNoOverrides = {
            ...navBarDefaultNoOverrides,
            NavBarDefaultNo: {
                width: "1440px"
            },
            TopNavMenuItem38493545: {
                overrides: {
                    NavText: {
                        children: (
                            <span
                                style={{ fontWeight: '600', color: 'rgba(49,91,155,1)', textDecoration: 'none', cursor: 'pointer' }}
                            >
                                Job Outlook
                            </span>
                        )
                    }
                }
            },
            TopNavMenuItem38493546: {
                overrides: {
                    NavText: {
                        children: (
                            <span
                                style={{ fontWeight: '600', color: 'rgba(49,91,155,1)', textDecoration: 'none', cursor: 'pointer' }}
                            >
                                About
                            </span>
                        )
                    }
                }
            },
            TopNavMenuItem38493547: {
                overrides: {
                    NavText: {
                        children: (
                            <span
                                style={{ fontWeight: '600', color: 'rgba(49,91,155,1)', textDecoration: 'none', cursor: 'pointer' }}
                            >
                                Blog
                            </span>
                        )
                    }
                }
            },
            TopNavMenuItem38493548: {
                overrides: {
                    NavText: {
                        children: (
                            <span
                                style={{ fontWeight: '600', color: 'rgba(49,91,155,1)', textDecoration: 'none', cursor: 'pointer' }}
                            >
                                Contact
                            </span>
                        )
                    }
                }
            },
            TopNavMenuItem38493550: {
                overrides: {
                    NavText: {
                        children: (
                            <span
                                onClick={redirectToLogin}
                                style={{ fontWeight: '600', color: 'rgba(49,91,155,1)', textDecoration: 'none', cursor: 'pointer' }}
                            >
                                Login
                            </span>
                        )
                    }
                }
            },

            Button: {
                onClick: redirectToLogin
            }


        }

        if (JSON.stringify(newNavBarDefaultNoOverrides) !== JSON.stringify(navBarDefaultNoOverrides)) {
            setNavBarDefaultNoOverrides(newNavBarDefaultNoOverrides);
        }

    }, [navBarDefaultNoOverrides]);

    const heroSectionOverrides = {
        HeroSection: {
            width: "1440px",
            // justifyContent: "left",
            // alignItems: "left",
            //display: "flex",
            //flexDirection: "column",
            //margin: "auto"
            //overflow: "hidden"
        },
        "ikimy hero image 1": {
            src: IkimyHeroImage,
            width: "1171px"
        },
        "ButtonTesting": {
            onClick: navigateToDashboard,
            style: { cursor: 'pointer' }
        },

    }

    const processSectionOverrides = {
        ProcessSection: {
            width: "1440px"
        },
        "image 4": {
            src: Step1,
            width: "100%",
        },

        "Line (Stroke)41587739": {
            viewBox: {
                width: "2px",
                height: "49px",
            },
            transform: "rotate(90deg) translateX(50%)"
        },

        "image 5": {
            src: Step2,
            width: "100%",
        },

        "Line (Stroke)41587746": {
            viewBox: {
                width: "2px",
                height: "49px",
            },
            transform: "rotate(90deg) translateX(50%)"
        },

        "image 6": {
            src: Step3,
            width: "100%",
        },

        "Line (Stroke)41587753": {
            viewBox: {
                width: "2px",
                height: "49px",
            },
            transform: "rotate(90deg) translateX(50%)"
        }
    }

    const component2Overrides = {
        Component2: {
            width: "1440px"
        },
        "Frame 1160464496": {
            width: "1440px"
        },
        "image 1": {
            src: PersonalizedJobMatch,
            width: "100%",
            height: "100%"

        }
    };

    const careerGuidanceOverrides = {
        CareerGuidance: {
            width: "1440px",
            justifyContent: "center",
            alignItems: "center"
        },

        "image 2": {
            src: HappyCandidate,
            width: "895",
            height: "544px",
            position: "relative"

        },

        "image 3": {
            src: WorkLifeGoals,
            position: "absolute",
            left: "-70px",
            bottom: "185px"
        },

        "image 4": {
            src: WorkValueSorter,
            position: "absolute",
            right: "0px",
            top: "580px"
        }
    };

    const learningResourcesOverrides = {
        LearningResources: {
            width: "1440px"
        },

        "image 3": {
            src: Learning,
            width: "835px",
            height: "512px",
            objectFit: "initial",
            objectPosition: "50% 50%"
        },

        "image 4": {
            src: Graph,
            position: "absolute",
            left: "217px",
            bottom: "378px"

        }

    };

    const founderInsightOverrides = {
        FounderInsight: {
            width: "1440px"
        },

        "unsplash:6SlQ22nMzGs": {
            src: DonaBaker,
        }
    }

    const faqOverrides = {
        "Work has to fit into life, not the other way around. We offer an optional one on one guided tour through the Ikimy process. Additionally, we provide other paid personal surveys and assessments to help you build greater awareness and direction on the life you want to lead.": {
            //children: ""
        }
    }

    const ctaOverrides = {
        "Frame 1160464095": {
            position: "absolute",
            left: "82px",
            top: "40px"
        },
        AdobeStock_272999282_Preview: {
            src: FindYourIdealJob,
            //objectFit: "cover",

        },

        Button: {
            onClick: navigateToDashboard,
            style: { cursor: 'pointer' }     
        },
    }

    const footerOverrides = {
        Home: {
            children: (
                <span
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                >
                    Home
                </span>
            )
        },

        About: {
            children: (
                <span
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                >
                    About
                </span>
            )
        },

        Candidate: {
            children: (
                <span
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                >
                    Candidate
                </span>
            )
        },

        Employer: {
            children: (
                <span
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                >
                    Employer
                </span>
            )
        },

        "Privacy Policy": {
            children: (
                <span
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                >
                    Privacy Policy
                </span>
            )
        },

        "Terms of Use": {
            children: (
                <span
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                >
                    Terms of Use
                </span>
            )
        },

        Blog: {
            children: (
                <span
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                >
                    Blog
                </span>
            )
        },

        "Contact Us": {
            children: (
                <span
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                >
                    Contact Us
                </span>
            )
        },



        // About?: PrimitiveOverrideProps<TextProps>;
        // Candidate?: PrimitiveOverrideProps<TextProps>;
        // Employer?: PrimitiveOverrideProps<TextProps>;
        // Item38561845?: PrimitiveOverrideProps<FlexProps>;
        // Resources?: PrimitiveOverrideProps<TextProps>;
        // "Privacy Policy"?: PrimitiveOverrideProps<TextProps>;
        // "Terms of Use"?: PrimitiveOverrideProps<TextProps>;
        // Blog?: PrimitiveOverrideProps<TextProps>;
        // "Contact Us"?: PrimitiveOverrideProps<TextProps>;
    };


  
    

    return (
        <div className="wrapper">
            <NavBarDefaultNo overrides={navBarDefaultNoOverrides}/>
            <HeroSection overrides={heroSectionOverrides}></HeroSection>
            <ProcessSection overrides={processSectionOverrides}></ProcessSection>
            <Component2 overrides={component2Overrides}></Component2>
            <CareerGuidance overrides={careerGuidanceOverrides}></CareerGuidance>
            <LearningResources overrides={learningResourcesOverrides}></LearningResources>
            <FounderInsight overrides={founderInsightOverrides}></FounderInsight>
            <FAQ overrides={faqOverrides}></FAQ>
            <CTA overrides={ctaOverrides}></CTA>
            <Footer overrides={footerOverrides}></Footer>   
        </div>

    );
}

export default Home;