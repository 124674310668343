/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import AppLogo from "./AppLogo";
import {
  Button,
  CheckboxField,
  Flex,
  PasswordField,
  PhoneNumberField,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
export default function Signup(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="24px"
      direction="column"
      width="771px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      boxShadow="0px 4px 25px rgba(0.20000000298023224, 0.20000000298023224, 0.20000000298023224, 0.05000000074505806)"
      borderRadius="4px"
      padding="64px 60px 64px 60px"
      {...getOverrideProps(overrides, "Signup")}
      {...rest}
    >
      <AppLogo
        display="flex"
        gap="4.2016801834106445px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="6.722688674926758px 6.722688674926758px 6.722688674926758px 6.722688674926758px"
        darkMode="False"
        {...getOverrideProps(overrides, "AppLogo")}
      ></AppLogo>
      <Text
        fontFamily="Inter"
        fontSize="32px"
        fontWeight="700"
        color="rgba(48,64,80,1)"
        lineHeight="48px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="I’m ready to be discovered"
        {...getOverrideProps(overrides, "I\u2019m ready to be discovered")}
      ></Text>
      <Flex
        gap="23px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 99")}
      >
        <TextField
          width="unset"
          height="unset"
          label="First Name *"
          placeholder="Type your first name"
          grow="1"
          shrink="1"
          basis="0"
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "TextField38493588")}
        ></TextField>
        <TextField
          width="unset"
          height="unset"
          label="Last Name *"
          placeholder="Type your last name"
          grow="1"
          shrink="1"
          basis="0"
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "TextField38493589")}
        ></TextField>
      </Flex>
      <Flex
        gap="23px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 100")}
      >
        <TextField
          width="unset"
          height="unset"
          label="E-mail *"
          placeholder="Type your e-mail"
          grow="1"
          shrink="1"
          basis="0"
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "TextField38493591")}
        ></TextField>
      </Flex>
      <Flex
        gap="23px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 101")}
      >
        <PhoneNumberField
          width="unset"
          height="unset"
          label="Phone *"
          placeholder="Type your phone number"
          grow="1"
          shrink="1"
          basis="0"
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "PhoneNumberField")}
        ></PhoneNumberField>
      </Flex>
      <Flex
        gap="23px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 102")}
      >
        <PasswordField
          width="unset"
          height="72px"
          label="Password  *"
          placeholder="Type your password"
          grow="1"
          shrink="1"
          basis="0"
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          hideShowPassword={false}
          {...getOverrideProps(overrides, "PasswordField38493595")}
        ></PasswordField>
        <PasswordField
          width="unset"
          height="unset"
          label="Confirm Password  *"
          placeholder="Confirm your password"
          grow="1"
          shrink="1"
          basis="0"
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          hideShowPassword={false}
          {...getOverrideProps(overrides, "PasswordField38493596")}
        ></PasswordField>
      </Flex>
      <Flex
        gap="23px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 103")}
      >
        <CheckboxField
          width="unset"
          height="unset"
          label="Agree with Terms of service and Privacy Policy"
          justifyContent="flex-start"
          grow="1"
          shrink="1"
          basis="0"
          size="default"
          defaultChecked={false}
          isDisabled={false}
          labelPosition="end"
          {...getOverrideProps(overrides, "CheckboxField")}
        ></CheckboxField>
      </Flex>
      <Button
        width="unset"
        height="unset"
        shrink="0"
        alignSelf="stretch"
        size="default"
        isDisabled={false}
        variation="primary"
        children="Sign Up"
        {...getOverrideProps(overrides, "Button")}
      ></Button>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="400"
        color="rgba(25,25,25,1)"
        lineHeight="24px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Already have an account? Login"
        {...getOverrideProps(overrides, "Already have an account? Login")}
      ></Text>
    </Flex>
  );
}
