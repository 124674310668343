import React from 'react';
import "./Loader.css";
import { Loader as AmplifyLoader } from '@aws-amplify/ui-react';

const Loader = ({ width = "10rem", height = "10rem", transform = "-50%, -50%" }) => {
    const containerStyle = {
        transform: `translate(${transform})`
    }
    return (
        <div className="loader-overlay">
            <div className="loader-container" style={containerStyle}>
                <AmplifyLoader width={width} height={height}></AmplifyLoader>
            </div>
        </div>
    )
};

export default Loader;