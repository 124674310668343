/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
export default function CareerGuidance(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="10px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "CareerGuidance")}
      {...rest}
    >
      <Flex
        gap="57px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="120px 70px 120px 70px"
        {...getOverrideProps(overrides, "Frame 1160464496")}
      >
        <Flex
          gap="64px"
          direction="column"
          width="1000px"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 32px 0px 32px"
          {...getOverrideProps(overrides, "Text")}
        >
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 1160464423")}
          >
            <Text
              fontFamily="Inter"
              fontSize="40px"
              fontWeight="700"
              color="rgba(16,38,65,1)"
              lineHeight="48px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="-0.25px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Career Guidance "
              {...getOverrideProps(overrides, "Career Guidance")}
            ></Text>
            <Text
              fontFamily="Inter"
              fontSize="18px"
              fontWeight="400"
              color="rgba(30,27,27,1)"
              lineHeight="30.600000381469727px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Work has to fit into life, not the other way around. We offer an optional one on one guided tour through the Ikimy process. Additionally, we provide other paid personal surveys and assessments to help you build greater awareness and direction on the life you want to lead."
              {...getOverrideProps(
                overrides,
                "Work has to fit into life, not the other way around. We offer an optional one on one guided tour through the Ikimy process. Additionally, we provide other paid personal surveys and assessments to help you build greater awareness and direction on the life you want to lead."
              )}
            ></Text>
          </Flex>
        </Flex>
        <Image
          width="1291px"
          height="544px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          {...getOverrideProps(overrides, "image 2")}
        ></Image>
        <Image
          width="392px"
          height="350px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          borderRadius="20px"
          padding="0px 0px 0px 0px"
          objectFit="unset"
          {...getOverrideProps(overrides, "image 3")}
        ></Image>
        <Image
          width="462px"
          height="193px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          borderRadius="20px"
          padding="0px 0px 0px 0px"
          objectFit="unset"
          {...getOverrideProps(overrides, "image 4")}
        ></Image>
      </Flex>
    </Flex>
  );
}
