import { signUpError, signUpSuccess, signUpRequest as signUpRequestAction, confirmSignUpRequest as confirmSignUpRequestAction,  signUpAwaitingVerification, confirmSignUpError, confirmSignUpSuccess, resendVerificationRequest as resendVerificationRequestAction, resendVerificationError, resendVerificationSuccess, forgotPasswordRequest as forgotPasswordRequestAction, forgotPasswordError, forgotPasswordSuccess, resetPasswordRequest as resetPasswordRequestAction, resetPasswordError, resetPasswordSuccess, signInRequest as signInRequestAction, signInError, signInSuccess } from "./action";

const apiURL = process.env.REACT_APP_BACKEND_CANDIDATE_SERVICE

export function signUpRequest(dispatch, signUpRequestModel) {
    dispatch(signUpRequestAction());
    fetch(`${apiURL}/auth/signup`, {
        method: "POST",
        body: JSON.stringify(signUpRequestModel),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        if (!response.ok) {
            return response.json().then(err => {
                if (((response.status === 409) && (err.detail)) && (err.detail === `User with this email ${signUpRequestModel.email} is awaiting verification.`)) {
                    dispatch(signUpAwaitingVerification(err));
                }
                else {
                    const errMessage = err.detail || 'An error occured during signup'
                    dispatch(signUpError({"status": response.status, "message": errMessage}));
                }
            }).catch(() => {
                dispatch(signUpError({"status": response.status, "message": 'An error occured during signup'}));
            });
        } else {
            return response.json().then(data => {
                dispatch(signUpSuccess(data));
            })
        }
    },

    (error) => {
        console.error("Problem with sign up operation", error);
        dispatch(signUpError({"status": "Network Error", "message": error.message || 'Network error occurred during signup'}));
    });
}


export function confirmSignUpRequest(dispatch, confirmSignUpRequestModel) {
    dispatch(confirmSignUpRequestAction());
    fetch(`${apiURL}/auth/signup/confirm`, {
        method: "POST",
        body: JSON.stringify(confirmSignUpRequestModel),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        if (!response.ok) {
            return response.json().then(err => {
                const errMessage = err.detail || 'An error occured during confirm signup'
                dispatch(confirmSignUpError({"status": response.status, "message": errMessage}));
            }).catch(() => {
                dispatch(confirmSignUpError({"status": response.status, "message": 'An error occured during confirm signup'}));
            });
        } else {
            return response.json().then(data => {
                dispatch(confirmSignUpSuccess(data));
            })
        }
    },

    (error) => {
        console.error("Problem with confirm sign up operation", error);
        dispatch(confirmSignUpError({"status": "Network Error", "message": error.message || 'Network error occurred during signup'}));
    });
}


export function resendVerificationRequest(dispatch, resendVerificationRequestModel) {
    dispatch(resendVerificationRequestAction());
    fetch(`${apiURL}/auth/resendverification`, {
        method: "PUT",
        body: JSON.stringify(resendVerificationRequestModel),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        if (!response.ok) {
            return response.json().then(err => {
                const errMessage = err.detail || 'An error occured during resend verification'
                dispatch(resendVerificationError({"status": response.status, "message": errMessage}));
            }).catch(() => {
                dispatch(resendVerificationError({"status": response.status, "message": 'An error occured during resend verification'}));
            });
        } else {
            return response.json().then(data => {
                dispatch(resendVerificationSuccess(data));
            })
        }
    },

    (error) => {
        console.error("Problem with resend verification operation", error);
        dispatch(resendVerificationError({"status": "Network Error", "message": error.message || 'Network error occurred during resend verification'}));
    });
}

export function forgotPasswordRequest(dispatch, forgotPasswordRequestModel) {
    dispatch(forgotPasswordRequestAction());
    fetch(`${apiURL}/auth/forgotpassword`, {
        method: "POST",
        body: JSON.stringify(forgotPasswordRequestModel),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        if (!response.ok) {
            return response.json().then(err => {
                const errMessage = err.detail || 'An error occured during forgot password'
                dispatch(forgotPasswordError({"status": response.status, "message": errMessage}));
            }).catch(() => {
                dispatch(forgotPasswordError({"status": response.status, "message": 'An error occured during forgot password'}));
            });
        } else {
            return response.json().then(data => {
                dispatch(forgotPasswordSuccess(data));
            })
        }
    },

    (error) => {
        console.error("Problem with forgot password operation", error);
        dispatch(forgotPasswordError({"status": "Network Error", "message": error.message || 'Network error occurred during forgot password'}));
    });
}

export function resetPasswordRequest(dispatch, resetPasswordRequestModel) {
    dispatch(resetPasswordRequestAction());
    fetch(`${apiURL}/auth/resetpassword`, {
        method: "PUT",
        body: JSON.stringify(resetPasswordRequestModel),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        if (!response.ok) {
            return response.json().then(err => {
                const errMessage = err.detail || 'An error occured during reset password'
                dispatch(resetPasswordError({"status": response.status, "message": errMessage}));
            }).catch(() => {
                dispatch(resetPasswordError({"status": response.status, "message": 'An error occured during reset password'}));
            });
        } else {
            return response.json().then(data => {
                dispatch(resetPasswordSuccess(data));
            })
        }
    },

    (error) => {
        console.error("Problem with reset password operation", error);
        dispatch(resetPasswordError({"status": "Network Error", "message": error.message || 'Network error occurred during reset password'}));
    });
}

export function signInRequest(dispatch, signInRequestModel) {
    dispatch(signInRequestAction());
    const env = process.env.REACT_APP_ENV;
    let url;
    if (env && env === "local") {
        url = `${apiURL}/auth/login`;
    }
    else {
        url = "/auth/login";
    }
    fetch(url, {
        method: "POST",
        body: JSON.stringify(signInRequestModel),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        if (!response.ok) {
            return response.json().then(err => {
                const errMessage = err.detail || 'An error occured during sign in'
                dispatch(signInError({"status": response.status, "message": errMessage}));
            }).catch(() => {
                dispatch(signInError({"status": response.status, "message": 'An error occured during sign in'}));
            });
        } else {
            return response.json().then(data => {
                dispatch(signInSuccess(data));
            })
        }
    },

    (error) => {
        console.error("Problem with sign in operation", error);
        dispatch(signInError({"status": "Network Error", "message": error.message || 'Network error occurred during sign in'}));
    });
}