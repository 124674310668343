import "./Error.css";

const Error = ({ errors, onClose }) => {
  return (
    <div className="error-modal">
      {errors.filter(error => error.message).map((error, index) => (
        <div key={index} className="error-content">
          <span className="error-message">{error.message}</span>
          <button className="close-button" onClick={() => onClose(index)}>X</button>
        </div>
      ))}
    </div>
  );
};

export default Error;