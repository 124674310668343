/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function Avartar(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="70px"
      height="70px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 70, height: 70 }}
      paths={[
        {
          d: "M70 35C70 54.33 54.33 70 35 70C15.67 70 0 54.33 0 35C0 15.67 15.67 0 35 0C54.33 0 70 15.67 70 35Z",
          fill: "rgba(217,217,217,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
        {
          d: "M45.7891 41.933C42.2285 35.8074 36.6388 31.5092 30.1437 29.6863C33.3015 28.0882 35.8266 25.4779 37.312 22.2762C38.7973 19.0745 39.1562 15.4681 38.3309 12.0384C37.5056 8.60877 35.5442 5.5558 32.7628 3.37178C29.9815 1.18777 26.5423 -7.96575e-16 23 0C19.4577 7.96575e-16 16.0185 1.18777 13.2372 3.37178C10.4558 5.5558 8.49438 8.60877 7.66907 12.0384C6.84377 15.4681 7.20272 19.0745 8.68805 22.2762C10.1734 25.4779 12.6985 28.0882 15.8563 29.6863C9.36123 31.507 3.7715 35.8052 0.210911 41.933C0.113142 42.0861 0.0474935 42.2573 0.0179155 42.4364C-0.0116625 42.6154 -0.00455854 42.7985 0.0387992 42.9748C0.082157 43.151 0.160873 43.3167 0.270211 43.4618C0.37955 43.607 0.517251 43.7286 0.675032 43.8194C0.832813 43.9102 1.00741 43.9683 1.18833 43.9902C1.36924 44.0121 1.55273 43.9973 1.72776 43.9467C1.90278 43.8962 2.06573 43.8109 2.20679 43.696C2.34786 43.5811 2.46413 43.4391 2.5486 43.2783C6.8749 35.8366 14.5186 31.3948 23 31.3948C31.4814 31.3948 39.1251 35.8366 43.4514 43.2783C43.5359 43.4391 43.6521 43.5811 43.7932 43.696C43.9343 43.8109 44.0972 43.8962 44.2722 43.9467C44.4473 43.9973 44.6308 44.0121 44.8117 43.9902C44.9926 43.9683 45.1672 43.9102 45.325 43.8194C45.4827 43.7286 45.6205 43.607 45.7298 43.4618C45.8391 43.3167 45.9178 43.151 45.9612 42.9748C46.0046 42.7985 46.0117 42.6154 45.9821 42.4364C45.9525 42.2573 45.8869 42.0861 45.7891 41.933ZM9.93777 15.6997C9.93777 13.1277 10.7039 10.6134 12.1392 8.47482C13.5744 6.33623 15.6145 4.66941 18.0013 3.68513C20.3881 2.70085 23.0145 2.44332 25.5483 2.9451C28.0821 3.44688 30.4096 4.68544 32.2364 6.50416C34.0632 8.32287 35.3072 10.6401 35.8112 13.1627C36.3153 15.6853 36.0566 18.3001 35.0679 20.6763C34.0793 23.0526 32.4051 25.0836 30.257 26.5126C28.1089 27.9416 25.5835 28.7043 23 28.7043C19.537 28.7001 16.217 27.3287 13.7682 24.8907C11.3195 22.4528 9.94194 19.1475 9.93777 15.6997Z",
          fill: "rgba(58,81,117,1)",
          fillRule: "nonzero",
          style: { transform: "translate(17.14%, 18.57%)" },
        },
      ]}
      {...getOverrideProps(overrides, "Avartar")}
      {...rest}
    ></Icon>
  );
}
