/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Divider, Flex, Icon, Text } from "@aws-amplify/ui-react";
import IconCheck from "./IconCheck";
export default function SignupLeftSection(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="2px"
      direction="column"
      width="678px"
      height="863px"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "SignupLeftSection")}
      {...rest}
    >
      <Flex
        gap="32px"
        direction="column"
        width="678px"
        height="863px"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="107px 60px 107px 60px"
        backgroundColor="rgba(33,47,69,1)"
        {...getOverrideProps(overrides, "IntroLayoutDarkMode")}
      >
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 1160464407")}
        >
          <Icon
            width="223.63px"
            height="39.63px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 226.549479128048,
              height: 62.78978840403215,
            }}
            paths={[
              {
                d: "M0.454816 39.6202C72.9544 16.2138 146.767 7.2006 220.081 10.1351C221.537 10.1938 223.594 8.24582 223.631 5.54834C223.669 2.80132 221.542 0.855576 220.081 0.77261C146.149 -3.47474 72.8511 9.89983 0.262434 38.1824C-0.198927 38.3626 0.000524181 39.7673 0.454816 39.6202Z",
                fill: "rgba(204,164,59,1)",
                fillRule: "evenodd",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            {...getOverrideProps(overrides, "Fill 90")}
          ></Icon>
          <Text
            fontFamily="Inter"
            fontSize="40px"
            fontWeight="700"
            color="rgba(254,254,254,1)"
            lineHeight="48px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Get discovered!"
            {...getOverrideProps(overrides, "Get discovered!")}
          ></Text>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 1160464408")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(254,254,254,1)"
            lineHeight="27.200000762939453px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="483px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Want to use your skills and personal strengths in a job where you are challenged and more engaged? Sign up for our unique candidate discovery process. Let us help you discover where you can do your best work."
            {...getOverrideProps(
              overrides,
              "Want to use your skills and personal strengths in a job where you are challenged and more engaged? Sign up for our unique candidate discovery process. Let us help you discover where you can do your best work."
            )}
          ></Text>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 1073")}
        >
          <Flex
            gap="17.473960876464844px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="14.678126335144043px 0px 16.07604217529297px 0px"
            {...getOverrideProps(overrides, "Frame 1071")}
          >
            <IconCheck
              width="27.26px"
              height="27.26px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "IconCheck38493685")}
            ></IconCheck>
            <Text
              fontFamily="Inter"
              fontSize="19.57083511352539px"
              fontWeight="500"
              color="rgba(255,255,255,1)"
              lineHeight="33.27042007446289px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Personalized Job Matching"
              {...getOverrideProps(overrides, "Personalized Job Matching")}
            ></Text>
          </Flex>
          <Divider
            width="unset"
            height="1px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider38749262")}
          ></Divider>
          <Flex
            gap="17.473960876464844px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="14.678126335144043px 0px 16.07604217529297px 0px"
            {...getOverrideProps(overrides, "Frame 1070")}
          >
            <IconCheck
              width="27.26px"
              height="27.26px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "IconCheck38493688")}
            ></IconCheck>
            <Text
              fontFamily="Inter"
              fontSize="19.57083511352539px"
              fontWeight="500"
              color="rgba(255,255,255,1)"
              lineHeight="33.27042007446289px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Customized Alternative Job Recommendations"
              {...getOverrideProps(
                overrides,
                "Customized Alternative Job Recommendations"
              )}
            ></Text>
          </Flex>
          <Divider
            width="unset"
            height="1px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider38748040")}
          ></Divider>
          <Flex
            gap="17.473960876464844px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="14.678126335144043px 0px 16.07604217529297px 0px"
            {...getOverrideProps(overrides, "Frame 1068")}
          >
            <IconCheck
              width="27.26px"
              height="27.26px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "IconCheck38493691")}
            ></IconCheck>
            <Text
              fontFamily="Inter"
              fontSize="19.57083511352539px"
              fontWeight="500"
              color="rgba(255,255,255,1)"
              lineHeight="33.27042007446289px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Life style coaching tool"
              {...getOverrideProps(overrides, "Life style coaching tool")}
            ></Text>
          </Flex>
          <Divider
            width="unset"
            height="1px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider38748330")}
          ></Divider>
          <Flex
            gap="17.473960876464844px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="14.678126335144043px 0px 16.07604217529297px 0px"
            {...getOverrideProps(overrides, "Frame 1069")}
          >
            <IconCheck
              width="27.26px"
              height="27.26px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "IconCheck38493694")}
            ></IconCheck>
            <Text
              fontFamily="Inter"
              fontSize="19.57083511352539px"
              fontWeight="500"
              color="rgba(255,255,255,1)"
              lineHeight="33.27042007446289px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Free Personal Assessments"
              {...getOverrideProps(overrides, "Free Personal Assessments")}
            ></Text>
          </Flex>
          <Divider
            width="unset"
            height="1px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider38748636")}
          ></Divider>
          <Flex
            gap="17.473960876464844px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="14.678126335144043px 0px 16.07604217529297px 0px"
            {...getOverrideProps(overrides, "Frame 1072")}
          >
            <IconCheck
              width="27.26px"
              height="27.26px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "IconCheck38493697")}
            ></IconCheck>
            <Text
              fontFamily="Inter"
              fontSize="19.57083511352539px"
              fontWeight="500"
              color="rgba(255,255,255,1)"
              lineHeight="33.27042007446289px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Custom Resume and Cover Letter Downloads"
              {...getOverrideProps(
                overrides,
                "Custom Resume and Cover Letter Downloads"
              )}
            ></Text>
          </Flex>
          <Divider
            width="unset"
            height="1px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider38749888")}
          ></Divider>
        </Flex>
      </Flex>
    </Flex>
  );
}
