/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Icon, Image, Text } from "@aws-amplify/ui-react";
export default function ProcessSection(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="55px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="120px 110px 120px 110px"
      {...getOverrideProps(overrides, "ProcessSection")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="column"
        width="483px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Process Left")}
      >
        <Flex
          gap="22px"
          direction="column"
          width="463px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 110")}
        >
          <Text
            fontFamily="Inter"
            fontSize="48px"
            fontWeight="700"
            color="rgba(30,27,27,1)"
            lineHeight="60px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="444px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="We are turning&#x2028;HR around"
            {...getOverrideProps(overrides, "We are turning\u2028HR around")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="18px"
            fontWeight="400"
            color="rgba(30,27,27,1)"
            lineHeight="30.600000381469727px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="463px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Ikimy's algorithm pinpoints your favorite activities from past job experiences. It then suggests roles aligned with your preferences. This tailored approach ensures you discover opportunities that truly match your interests and abilities. "
            {...getOverrideProps(
              overrides,
              "Ikimy's algorithm pinpoints your favorite activities from past job experiences. It then suggests roles aligned with your preferences. This tailored approach ensures you discover opportunities that truly match your interests and abilities."
            )}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="18px"
            fontWeight="400"
            color="rgba(30,27,27,1)"
            lineHeight="30.600000381469727px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="463px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Just like a dating platform helps you find your suitable match in another person, Ikimy helps you find jobs that best fit with the life you want to lead. It's a personalized job search that's uniquely yours."
            {...getOverrideProps(
              overrides,
              "Just like a dating platform helps you find your suitable match in another person, Ikimy helps you find jobs that best fit with the life you want to lead. It's a personalized job search that's uniquely yours."
            )}
          ></Text>
        </Flex>
      </Flex>
      <Flex
        gap="27px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Process Right")}
      >
        <Flex
          gap="32px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "step1")}
        >
          <Flex
            gap="17px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 116046440741587737")}
          >
            <Image
              width="72px"
              height="77px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              {...getOverrideProps(overrides, "image 4")}
            ></Image>
            <Icon
              width="49px"
              height="2px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 2.0000021418582037,
                height: 49.00000008742245,
              }}
              paths={[
                {
                  d: "M0 1C0 0.447715 0.447715 0 1 0L2.95833 0C3.51062 0 3.95833 0.447715 3.95833 1C3.95833 1.55228 3.51062 2 2.95833 2L1 2C0.447715 2 0 1.55228 0 1ZM5.875 1C5.875 0.447715 6.32272 0 6.875 0L10.7917 0C11.344 0 11.7917 0.447715 11.7917 1C11.7917 1.55228 11.344 2 10.7917 2L6.875 2C6.32272 2 5.875 1.55228 5.875 1ZM13.7083 1C13.7083 0.447715 14.156 0 14.7083 0L18.625 0C19.1773 0 19.625 0.447715 19.625 1C19.625 1.55228 19.1773 2 18.625 2L14.7083 2C14.156 2 13.7083 1.55228 13.7083 1ZM21.5417 1C21.5417 0.447715 21.9894 0 22.5417 0L26.4583 0C27.0106 0 27.4583 0.447715 27.4583 1C27.4583 1.55228 27.0106 2 26.4583 2L22.5417 2C21.9894 2 21.5417 1.55228 21.5417 1ZM29.375 1C29.375 0.447715 29.8227 0 30.375 0L34.2917 0C34.8439 0 35.2917 0.447715 35.2917 1C35.2917 1.55228 34.8439 2 34.2917 2L30.375 2C29.8227 2 29.375 1.55228 29.375 1ZM37.2083 1C37.2083 0.447715 37.656 0 38.2083 0L42.125 0C42.6773 0 43.125 0.447715 43.125 1C43.125 1.55228 42.6773 2 42.125 2L38.2083 2C37.656 2 37.2083 1.55228 37.2083 1ZM45.0417 1C45.0417 0.447715 45.4894 0 46.0417 0L48 0C48.5523 0 49 0.447715 49 1C49 1.55228 48.5523 2 48 2L46.0417 2C45.4894 2 45.0417 1.55228 45.0417 1Z",
                  fill: "rgba(204,164,59,1)",
                  fillRule: "evenodd",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "Line (Stroke)41587739")}
            ></Icon>
          </Flex>
          <Flex
            gap="8px"
            direction="column"
            width="576px"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Text41587740")}
          >
            <Text
              fontFamily="Inter"
              fontSize="32px"
              fontWeight="700"
              color="rgba(30,27,27,1)"
              lineHeight="48px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="-0.81px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="We Clarify"
              {...getOverrideProps(overrides, "We Clarify")}
            ></Text>
            <Text
              fontFamily="Inter"
              fontSize="18px"
              fontWeight="400"
              color="rgba(30,27,27,1)"
              lineHeight="30.600000381469727px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="499px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Enter details of your work experience, education & training and some information on your work preferences and work activity interests."
              {...getOverrideProps(
                overrides,
                "Enter details of your work experience, education & training and some information on your work preferences and work activity interests."
              )}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="32px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Step2")}
        >
          <Flex
            gap="17px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 116046440741587744")}
          >
            <Image
              width="72px"
              height="77px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              {...getOverrideProps(overrides, "image 5")}
            ></Image>
            <Icon
              width="51px"
              height="2px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 2.0000022292806534,
                height: 51.00000008742245,
              }}
              paths={[
                {
                  d: "M0 1C0 0.447715 0.447715 0 1 0L3.04167 0C3.59395 0 4.04167 0.447715 4.04167 1C4.04167 1.55228 3.59395 2 3.04167 2L1 2C0.447715 2 0 1.55228 0 1ZM6.125 1C6.125 0.447715 6.57272 0 7.125 0L11.2083 0C11.7606 0 12.2083 0.447715 12.2083 1C12.2083 1.55228 11.7606 2 11.2083 2L7.125 2C6.57272 2 6.125 1.55228 6.125 1ZM14.2917 1C14.2917 0.447715 14.7394 0 15.2917 0L19.375 0C19.9273 0 20.375 0.447715 20.375 1C20.375 1.55228 19.9273 2 19.375 2L15.2917 2C14.7394 2 14.2917 1.55228 14.2917 1ZM22.4583 1C22.4583 0.447715 22.906 0 23.4583 0L27.5417 0C28.094 0 28.5417 0.447715 28.5417 1C28.5417 1.55228 28.094 2 27.5417 2L23.4583 2C22.906 2 22.4583 1.55228 22.4583 1ZM30.625 1C30.625 0.447715 31.0727 0 31.625 0L35.7083 0C36.2606 0 36.7083 0.447715 36.7083 1C36.7083 1.55228 36.2606 2 35.7083 2L31.625 2C31.0727 2 30.625 1.55228 30.625 1ZM38.7917 1C38.7917 0.447715 39.2394 0 39.7917 0L43.875 0C44.4273 0 44.875 0.447715 44.875 1C44.875 1.55228 44.4273 2 43.875 2L39.7917 2C39.2394 2 38.7917 1.55228 38.7917 1ZM46.9583 1C46.9583 0.447715 47.406 0 47.9583 0L50 0C50.5523 0 51 0.447715 51 1C51 1.55228 50.5523 2 50 2L47.9583 2C47.406 2 46.9583 1.55228 46.9583 1Z",
                  fill: "rgba(204,164,59,1)",
                  fillRule: "evenodd",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "Line (Stroke)41587746")}
            ></Icon>
          </Flex>
          <Flex
            gap="8px"
            direction="column"
            width="537px"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Text41587747")}
          >
            <Text
              fontFamily="Inter"
              fontSize="32px"
              fontWeight="700"
              color="rgba(30,27,27,1)"
              lineHeight="48px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="-0.81px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="We Discover"
              {...getOverrideProps(overrides, "We Discover")}
            ></Text>
            <Text
              fontFamily="Inter"
              fontSize="18px"
              fontWeight="400"
              color="rgba(30,27,27,1)"
              lineHeight="30.600000381469727px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Complete a questionnaire on what improvements you would like to see in your life over the next 12 months. Work supports a lifestyle we aim to achieve. "
              {...getOverrideProps(
                overrides,
                "Complete a questionnaire on what improvements you would like to see in your life over the next 12 months. Work supports a lifestyle we aim to achieve."
              )}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="32px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Step3")}
        >
          <Flex
            gap="17px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 116046440741587751")}
          >
            <Image
              width="72px"
              height="77px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              {...getOverrideProps(overrides, "image 6")}
            ></Image>
            <Icon
              width="48px"
              height="2px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 2.0000020981460693,
                height: 48.00000008742245,
              }}
              paths={[
                {
                  d: "M0 1C0 0.447715 0.447715 0 1 0L2.91667 0C3.46895 0 3.91667 0.447715 3.91667 1C3.91667 1.55228 3.46895 2 2.91667 2L1 2C0.447715 2 0 1.55228 0 1ZM5.75 1C5.75 0.447715 6.19772 0 6.75 0L10.5833 0C11.1356 0 11.5833 0.447715 11.5833 1C11.5833 1.55228 11.1356 2 10.5833 2L6.75 2C6.19772 2 5.75 1.55228 5.75 1ZM13.4167 1C13.4167 0.447715 13.8644 0 14.4167 0L18.25 0C18.8023 0 19.25 0.447715 19.25 1C19.25 1.55228 18.8023 2 18.25 2L14.4167 2C13.8644 2 13.4167 1.55228 13.4167 1ZM21.0833 1C21.0833 0.447715 21.531 0 22.0833 0L25.9167 0C26.469 0 26.9167 0.447715 26.9167 1C26.9167 1.55228 26.469 2 25.9167 2L22.0833 2C21.531 2 21.0833 1.55228 21.0833 1ZM28.75 1C28.75 0.447715 29.1977 0 29.75 0L33.5833 0C34.1356 0 34.5833 0.447715 34.5833 1C34.5833 1.55228 34.1356 2 33.5833 2L29.75 2C29.1977 2 28.75 1.55228 28.75 1ZM36.4167 1C36.4167 0.447715 36.8644 0 37.4167 0L41.25 0C41.8023 0 42.25 0.447715 42.25 1C42.25 1.55228 41.8023 2 41.25 2L37.4167 2C36.8644 2 36.4167 1.55228 36.4167 1ZM44.0833 1C44.0833 0.447715 44.531 0 45.0833 0L47 0C47.5523 0 48 0.447715 48 1C48 1.55228 47.5523 2 47 2L45.0833 2C44.531 2 44.0833 1.55228 44.0833 1Z",
                  fill: "rgba(204,164,59,1)",
                  fillRule: "evenodd",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "Line (Stroke)41587753")}
            ></Icon>
          </Flex>
          <Flex
            gap="8px"
            direction="column"
            width="529px"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Text41587754")}
          >
            <Text
              fontFamily="Inter"
              fontSize="32px"
              fontWeight="700"
              color="rgba(30,27,27,1)"
              lineHeight="48px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="-0.81px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children=" We Match"
              {...getOverrideProps(overrides, "We Match")}
            ></Text>
            <Text
              fontFamily="Inter"
              fontSize="18px"
              fontWeight="400"
              color="rgba(30,27,27,1)"
              lineHeight="30.600000381469727px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Sit back and let Rhealize match you with recommended careers and available jobs that best fit with what you like and want right now in your life."
              {...getOverrideProps(
                overrides,
                "Sit back and let Rhealize match you with recommended careers and available jobs that best fit with what you like and want right now in your life."
              )}
            ></Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
