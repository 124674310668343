/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Icon } from "@aws-amplify/ui-react";
export default function Close(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        "Vector (Stroke)40598586": {},
        "Vector (Stroke)40598587": {},
        Vector: {},
        Close: {},
      },
      variantValues: { state: "Default" },
    },
    {
      overrides: {
        "Vector (Stroke)40598586": {
          viewBox: {
            minX: 0,
            minY: 0,
            width: 17.999887466430664,
            height: 17.99854278564453,
          },
          paths: [
            {
              d: "M17.7939 0.20602C18.0686 0.480714 18.0686 0.926081 17.7939 1.20077L1.20066 17.7925C0.92597 18.0672 0.480604 18.0672 0.20591 17.7925C-0.0687839 17.5178 -0.0687839 17.0725 0.20591 16.7978L16.7991 0.20602C17.0738 -0.0686735 17.5192 -0.0686735 17.7939 0.20602Z",
              fill: "rgba(92,102,112,1)",
              fillRule: "evenodd",
            },
          ],
        },
        "Vector (Stroke)40598587": {
          viewBox: {
            minX: 0,
            minY: 0,
            width: 17.819334030151367,
            height: 17.818435668945312,
          },
          paths: [
            {
              d: "M0.20602 0.20602C0.480714 -0.0686735 0.926081 -0.0686735 1.20077 0.20602L17.6133 16.6177C17.888 16.8924 17.888 17.3377 17.6133 17.6124C17.3386 17.8871 16.8932 17.8871 16.6186 17.6124L0.20602 1.20077C-0.0686735 0.926081 -0.0686735 0.480714 0.20602 0.20602Z",
              fill: "rgba(92,102,112,1)",
              fillRule: "evenodd",
            },
          ],
        },
        Vector: {
          viewBox: { minX: 0, minY: 0, width: 18, height: 17.998544692993164 },
          paths: [
            {
              d: "M17.794 1.20077C18.0687 0.926081 18.0687 0.480714 17.794 0.20602C17.5193 -0.0686735 17.0739 -0.0686735 16.7992 0.20602L9.0614 7.94317L1.32382 0.20602C1.04913 -0.0686735 0.603761 -0.0686735 0.329067 0.20602C0.0543734 0.480714 0.0543734 0.926081 0.329067 1.20077L8.0666 8.93788L0.206025 16.7978C-0.0686692 17.0725 -0.0686692 17.5178 0.206025 17.7925C0.480718 18.0672 0.926085 18.0672 1.20078 17.7925L9.06138 9.93261L16.7416 17.6124C17.0163 17.8871 17.4617 17.8871 17.7364 17.6124C18.0111 17.3377 18.0111 16.8924 17.7364 16.6177L10.0562 8.9379L17.794 1.20077Z",
              fill: "rgba(92,102,112,1)",
              fillRule: "evenodd",
            },
          ],
        },
        Close: {
          direction: "column",
          borderRadius: "100px",
          backgroundColor: "rgba(220,222,224,1)",
        },
      },
      variantValues: { state: "Hovered" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="11px 11px 11px 11px"
      display="flex"
      {...getOverrideProps(overrides, "Close")}
      {...rest}
    >
      <Icon
        width="18px"
        height="18px"
        viewBox={{ minX: 0, minY: 0, width: 18, height: 18 }}
        paths={[
          {
            d: "M17.794 1.20087C18.0687 0.926156 18.0687 0.480753 17.794 0.206037C17.5193 -0.068679 17.0739 -0.068679 16.7992 0.206037L9.06167 7.94354L1.32437 0.206037C1.04967 -0.068679 0.604306 -0.068679 0.329612 0.206037C0.0549185 0.480753 0.0549185 0.926156 0.329612 1.20087L8.06687 8.93833L0.20602 16.7991C-0.0686735 17.0738 -0.0686735 17.5192 0.20602 17.794C0.480714 18.0687 0.926081 18.0687 1.20077 17.794L9.06165 9.93314L16.7422 17.6138C17.0168 17.8886 17.4622 17.8886 17.7369 17.6138C18.0116 17.3391 18.0116 16.8937 17.7369 16.619L10.0564 8.93835L17.794 1.20087Z",
            fill: "rgba(92,102,112,1)",
            fillRule: "evenodd",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "Vector")}
      >
        <Icon
          width="18px"
          height="18px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 17.999889373779297,
            height: 17.999998092651367,
          }}
          paths={[
            {
              d: "M17.7939 0.206037C18.0686 0.480753 18.0686 0.926156 17.7939 1.20087L1.20066 17.794C0.92597 18.0687 0.480604 18.0687 0.20591 17.794C-0.0687839 17.5192 -0.0687839 17.0738 0.20591 16.7991L16.7991 0.206037C17.0738 -0.068679 17.5192 -0.068679 17.7939 0.206037Z",
              fill: "rgba(92,102,112,1)",
              fillRule: "evenodd",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="relative"
          {...getOverrideProps(overrides, "Vector (Stroke)40598586")}
        ></Icon>
        <Icon
          width="17.82px"
          height="17.82px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 17.8193359375,
            height: 17.81987762451172,
          }}
          paths={[
            {
              d: "M0.20602 0.206037C0.480714 -0.068679 0.926081 -0.068679 1.20077 0.206037L17.6133 16.619C17.888 16.8937 17.888 17.3391 17.6133 17.6138C17.3386 17.8886 16.8933 17.8886 16.6186 17.6138L0.20602 1.20087C-0.0686735 0.926156 -0.0686735 0.480753 0.20602 0.206037Z",
              fill: "rgba(92,102,112,1)",
              fillRule: "evenodd",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="relative"
          {...getOverrideProps(overrides, "Vector (Stroke)40598587")}
        ></Icon>
      </Icon>
    </Flex>
  );
}
