import { Button, Flex, Text, Image } from "@aws-amplify/ui-react";
import IkimyLogo from "./IkimyLogo.png";

export function isMobileDevice() {
    return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );
}

const MobileSupport = ({

}) => {

    return (
        <Flex
            width="393px"
            padding="252.5px 0px"
            direction="column"
            justifyContent="center"
            alignItems="center"
            backgroundColor="#FFF"
        >
            <Flex
                width="393px"
                padding="0px 40px"
                direction="column"
                alignItems="center"
                gap="28px"
            >
                <Image
                    width="95.164px"
                    height="43px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    objectFit="cover"
                    src={IkimyLogo}
                ></Image>
                <Flex
                    direction="column"
                    alignItems="center"
                    gap="12px"
                    alignSelf="stretch"
                >
                    <Text
                        alignSelf="stretch"
                        color="#222"
                        textAlign="center"
                        fontFamily="Inter"
                        fontSize="20px"
                        fontStyle="normal"
                        fontWeight="700"
                        lineHeight="normal"
                    >
                        Mobile Support Coming Soon!
                    </Text>
                    <Text
                        alignSelf="stretch"
                        color="var(--colors-font-tertiary, #5C6670)"
                        textAlign="center"
                        fontFamily="Inter"
                        fontSize="14px"
                        fontStyle="normal"
                        fontWeight="400"
                        lineHeight="150%"
                    >
                        For the full range of features and a seamless experience of our web app, kindly visit us on your desktop. 
                    </Text>
                </Flex>

            </Flex>
        </Flex>
    )

};

export default MobileSupport;