/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
export default function LearningResources(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="55px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="120px 180px 120px 180px"
      backgroundColor="rgba(246,246,246,1)"
      {...getOverrideProps(overrides, "LearningResources")}
      {...rest}
    >
      <Flex
        gap="64px"
        direction="column"
        width="936px"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 32px 0px 32px"
        {...getOverrideProps(overrides, "Text")}
      >
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 1160464424")}
        >
          <Text
            fontFamily="Inter"
            fontSize="40px"
            fontWeight="700"
            color="rgba(16,38,65,1)"
            lineHeight="48px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="-0.25px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Learning Resources"
            {...getOverrideProps(overrides, "Learning Resources")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="18px"
            fontWeight="400"
            color="rgba(30,27,27,1)"
            lineHeight="30.600000381469727px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Unlock your potential with our dynamic learning resources designed for job seekers. Cultivate a growth mindset and learn essential skills for navigating the ever-evolving landscape of work. Elevate your career journey starting today."
            {...getOverrideProps(
              overrides,
              "Unlock your potential with our dynamic learning resources designed for job seekers. Cultivate a growth mindset and learn essential skills for navigating the ever-evolving landscape of work. Elevate your career journey starting today."
            )}
          ></Text>
        </Flex>
      </Flex>
      <Image
        width="1078.5px"
        height="512px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        {...getOverrideProps(overrides, "image 3")}
      ></Image>
      <Image
        width="243px"
        height="174px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        objectFit="unset"
        {...getOverrideProps(overrides, "image 4")}
      ></Image>
    </Flex>
  );
}
